import { Entity } from '../core/Entity';
import { IResultLauncher } from '../data/entries/IResultLauncher';
import {
	ApolloClientMutateManageResultLauncher,
	ApolloClientQueryComputingHistory,
	ApolloClientQueryLauncherEquipmentsVersions
} from '../../infrastructure/ApolloClass/ResultLauncherClass';
import { QUERY_COMPUTING_HISTORY } from '../../infrastructure/ApolloClient/requests/QUERY_COMPUTING_HISTORY';
import { QUERY_LAUNCHER_EQUIPMENTS_VERSIONS } from '../../infrastructure/ApolloClient/requests/QUERY_LAUNCHER_EQUIPMENTS_VERSIONS';
import { MUTATE_MANAGE_RESULT_LAUNCHER } from '../../infrastructure/ApolloClient/requests/MUTATE_MANAGE_RESULT_LAUNCHER';
import { IQueryLauncherEquipmentsVersionsInput, IQueryLauncherEquipmentsVersionsOutput } from '../interfaces/IQueryLauncherEquipmentsVersions';
import { IMutateManageResultLauncherInput, IMutateManageResultLauncherOutput } from '../interfaces/IMutateManageResultLauncher';

export class ResultLaunchersEntity extends Entity {
	public data: undefined | IResultLauncher[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateManageResultLauncher ??= this.app.installer(ApolloClientMutateManageResultLauncher, MUTATE_MANAGE_RESULT_LAUNCHER);
		this.app.adapter.queryComputingHistory ??= this.app.installer(ApolloClientQueryComputingHistory, QUERY_COMPUTING_HISTORY);
		this.app.adapter.queryLauncherEquipmentsVersions ??= this.app.installer(ApolloClientQueryLauncherEquipmentsVersions, QUERY_LAUNCHER_EQUIPMENTS_VERSIONS);
		this.app.adapter.storeDataResultLaunchers?.({ loading: false, error: null, data: this });
	}

	get(datasetsIds?: string[], sampleId?: string): IResultLauncher[] | undefined {
		if (!this.data) {
			if (this._isQuerying || !datasetsIds) return;
			this.queryComputingHistory(datasetsIds, sampleId);
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDataResultLaunchers?.({ loading: false, error: null, data: this });
	}

	set(obj: IResultLauncher[] | undefined): void {
		this.data = obj;
		this.change();
	}

	update(obj: IResultLauncher ): void {
		if (!this.data) return;
		this.data = this.data.map(line => {
			if (line.id === obj.id) {
				return obj;
			}
			return line;
		});
		this.change();
	}

	private _storeError(type: Error) {
		this.app.adapter.storeDataResultLaunchers?.({
			loading: false,
			error: type,
			data: this
		});
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryComputingHistory(datasetsIds: string[], sample_id: undefined | string): Promise<void> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryComputingHistory, { datasets: datasetsIds, sample_id }).catch(err => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		// Set data to an empty array if error like PermissionDenied to avoid infinite loop calling the get method
		if (!data) {
			this.set([]);
			return;
		}
		this.set(data.computingHistory);
	}

	async queryLauncherEquipmentsVersions(input: IQueryLauncherEquipmentsVersionsInput): Promise<IQueryLauncherEquipmentsVersionsOutput> {
		return this.callApi(this.app.adapter.queryLauncherEquipmentsVersions, input);
	}

	async mutateManageResultLauncher(input: IMutateManageResultLauncherInput): Promise<IMutateManageResultLauncherOutput> {
		const data = await this.callApi(this.app.adapter.mutateManageResultLauncher, input);
		if (data.mutateManageResultLauncher.status !== 200) {
			throw new Error('Something went wrong');
		}
		this.update(data.mutateManageResultLauncher.resultLauncher);
		data.mutateManageResultLauncher.resultLauncher.datasets.forEach(dataset => {
			this.app.entities.datasets.updateDefinitiveResultStatus(dataset.id, data.mutateManageResultLauncher.resultLauncher.definitive);
			this.app.entities.datasets.updateLockStatus(dataset.id, dataset.collectOpen, dataset.validationOpen);
		});
		return data;
	}

}
