import { gql } from '@apollo/client';

export const QUERY_FUNCTIONAL_UNITS = gql`
    query QueryFunctionalUnits{
        functionalUnits {
            id
            name
            nameFr
            nameEn
            private
        }
    }
`;
