import { gql } from '@apollo/client';

export const MUTATE_EQUIPMENT_TYPE_MANAGEMENT = gql`
    mutation mutateEquipmentTypeManagement(

		$id: String!
        $category: String!
        $shortNameEn: String!
        $shortNameFr: String
        $specificationEn: String
        $specificationFr: String
        $descEn: String
        $descFr: String
        $defaultLifetime: Float
        $metadata: JSONString
        $impactFactors: [ImpactFactorInput]
        $remove: Boolean
        $electricityConsumption: Float
        $managedCompanyId: String
    ){
        mutateEquipmentTypeManagement(
			id: $id
			category: $category
			shortNameEn: $shortNameEn
			shortNameFr: $shortNameFr
			specificationEn: $specificationEn
			specificationFr: $specificationFr
			descEn: $descEn
			descFr: $descFr
			defaultLifetime: $defaultLifetime
			metadata: $metadata
			remove: $remove
			impactFactors: $impactFactors
            electricityConsumption: $electricityConsumption
            managedCompanyId: $managedCompanyId
        ){
            status,
            errors,
            equipmentType {
                id,
                name,
                category,
                shortName,
                shortNameEn,
                shortNameFr,
                specificationEn,
                specificationFr,
                descEn,
                descFr,
                defaultLifetime,
                metadata,
            }
        }
    }
`;
