
import { gql } from '@apollo/client';

export const QUERY_CMDB_EQUIPMENTS = gql`
    query QueryCmdbEquipments (
    	$datasetId: String!
    	$cmdbName: String!
    	$blockName: String!
    	$equipmentCategory: String!
    	$start: Int
    	$numberOfEquipments: Int
    )
    {
    	cmdbEquipments (
    	 	datasetId: $datasetId
    	 	cmdbName: $cmdbName
    	 	blockName: $blockName
    	 	equipmentCategory: $equipmentCategory
    	 	start: $start
    	 	numberOfEquipments: $numberOfEquipments
		) {
			id,
			name,
			matchingScore,
			matchingName,
			help,
			unit,
			unitShortName,
			priority,
			specification,
			quantity,
			quality,
			unknown
			lifetime,
			internalLifetime,
			reusePart,
			reuseLifetime,
			comment,
			assigned,
			assignedCount,
			forbidden,
			equipmentTypeId,
			flag,
			category,
			cmdb,
			defaultLifetime,
    	}
    }
`;
