import { Entity } from '../core/Entity';
import { ISample, ISampleListItem } from '../interfaces/ISample';
import { MUTATE_SAMPLES_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_SAMPLES_MANAGEMENT';
import { QUERY_SAMPLES } from '../../infrastructure/ApolloClient/requests/QUERY_SAMPLES';
import { QUERY_SAMPLE_LIST } from '../../infrastructure/ApolloClient/requests/QUERY_SAMPLE_LIST';
import { ApolloClientMutateSamplesManagement, ApolloClientQuerySampleList, ApolloClientQuerySamples } from '../../infrastructure/ApolloClass/SampleClass';
import { IQuerySampleListInput } from '../interfaces/IQuerySampleList';
import { IMutateSamplesManagementInput } from '../interfaces/IMutateSamplesManagement';

export class SampleEntity extends Entity {
	public data: ISampleListItem[] | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateSamplesManagement ??= this.app.installer(ApolloClientMutateSamplesManagement, MUTATE_SAMPLES_MANAGEMENT);
		this.app.adapter.querySamples ??= this.app.installer(ApolloClientQuerySamples, QUERY_SAMPLES);
		this.app.adapter.querySampleList ??= this.app.installer(ApolloClientQuerySampleList, QUERY_SAMPLE_LIST);
		this.app.adapter.storeDataSamples?.({ loading: false, data: this, error: null });
	}

	get() {
		if (!this.data) {
			if (this._isQuerying) return;
			this.querySampleList({});
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		return this.app.adapter.storeDataSamples?.({ loading: false, data: this, error: null });
	}

	set(obj: ISampleListItem[] | undefined): void {
		this.data = obj;
		this.change();
	}

	update(list: ISampleListItem[]): void {
		if (!list) return;
		// Remove items that are not in the list to handle deletion
		if (this.data) {
			this.data = this.data.filter((item) => {
				return list.findIndex((b) => b.id === item.id) !== -1;
			});
		}

		list.forEach((item) => {
			if (this.data === undefined) this.data = [];
			const idx = this.data.findIndex((b) => b.id === item.id);
			if (idx === -1 || idx === undefined) {
				this.data.push(item);
			}
			else {
				this.data[idx] = item;
			}
		});

		this.change();
	}

	private _storeError(err: Error) {
		this.app.adapter.storeDataSamples?.({ loading: false, data: this, error: err });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async querySampleList(input: IQuerySampleListInput): Promise<void> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.querySampleList, input).catch((err) => {
			this._isQuerying = false;
			this._storeError(err);
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.sampleList);
	}

	async querySamples(): Promise<ISample[]> {
		const data = await this.callApi(this.app.adapter.querySamples);
		return data.samples;
	}

	async mutateSamplesManagement(input: IMutateSamplesManagementInput): Promise<ISample[]> {
		const data = await this.callApi(this.app.adapter.mutateSamplesManagement, input);
		const sampleList: ISampleListItem[] = data.mutateSamplesManagement.samples.map((sample: ISample) => {
			return {
				id: sample.id,
				name: sample.name,
				desc: sample.desc,
				functionalUnit: sample.functionalUnit
			};
		});
		this.update(sampleList);
		return data.mutateSamplesManagement.samples;
	}
}
