import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import './polarChart.css';
import { getColors } from '../../../config';
import { labelSize } from '../utils/labelSize';
import { getNextAvailableColor } from '../../../utils/GraphsUtils';
import { PolarPluginDrawCircle } from '../plugins/PolarPluginDrawCircle';


/**
 * A polar chart component that displays data of a list of values
 *
 * @param data {{labels: string, values: number}[]} - The data to display in the chart
 * @param limit {number} - This limit value will be drawn in red on the chart
 *
 * @author Yacine Bentayeb
 */
export const PolarChart = ({ data, limit }: {data: {labels: string, values: number, color: string | undefined}[], limit?: number}): JSX.Element => {
	const labels = data.map(d => labelSize(d.labels));
	const values = data.map(d => d.values);

	const maxValue = Math.max(limit || 0, ...values);

	const defaultColors = getColors(labels.length);
	const colors: string[] = [];
	data.forEach(d => {
		if (d.color) colors.push(d.color);
		else colors.push(getNextAvailableColor(colors, defaultColors));
	});

	const chartData = {
		labels: labels,
		datasets: [
			{
				data: values,
				backgroundColor: colors,
			},
		],
	};

	return (
		<PolarArea
			className='dynamic_chart polar_chart'
			data={chartData}
			plugins={[PolarPluginDrawCircle(limit, maxValue)]}
			options={{
				layout: {
					padding: 5
				},
				plugins: {
					legend: {
						position: 'right',
						labels: {
							font: {
								size: 14,
								weight: 'bold',
							}
						}
					},
				},
				scales: {
					r: {
						ticks: {
							display: false,
						},
					},
				},
				responsive: true,
			}}
		/>
	);
};
