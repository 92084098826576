import React from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { translate } from '../../../../infrastructure/translations/translate';
import {
	GroupedBarChart,
	GroupedBarChartData
} from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { createDashboardComparisonObjectiveModal } from '../CreateDashboardComparisonObjectiveModal';
import { dashboardCompareToReference } from '../DashboardComparison';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../CreateDashboardComparisonBenchmarkModal';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { DashboardPinButton } from '../../DashboardPin';
import { parseByFootprintTypeDashboard } from '../ParseByFootprintTypeDashboard';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { useModal } from '../../../../viewComponents/modal/useModal';
import { dashboardLabelName } from '../../DashboardLabelName';
import { DashboardComparisonPluginGraphBarRefDiff } from '../../DashboardPluginGraphBarLegend';
import { TooltipItem } from 'chart.js/auto';
import { DashboardSelectReference } from '../../DashboardSelectReference';

const options: ChartProps<'bar'>['options'] = {
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	},
};
const colors = ['#086598','#46825a',]; 

export interface DashboardGlobalComparisonGraphData {
	graph: (GroupedBarChartData | null)[];
	objective?: { year: number, percent: number };
	indicators: string[];
	lifeCycleSteps: string[];
	indicator: string;
	lifeCycleStep: string;
	datasetRef: string | null;
}
export const DashboardGlobalComparisonGraph = ({ showTable, data }:{ showTable: boolean, data: DashboardGlobalComparisonGraphData }) => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { graph: graphData, objective, indicator: selectIndicator, datasetRef } = data;
	const unit = indicators?.find(i => i.id === selectIndicator)?.unit ?? translate('results.valuePBCI') as string;

	return (
		<div className="dashboard_graph">
			{!showTable &&
			<div className="dashboard_chart_container">
				<div className="chart_indicator_unit">
					{unit}
				</div>
				<GroupedBarChart
					data={graphData}
					datasetRef={datasetRef}
					options={options}
					thin={20}
					percentage={.8}
					categoryPercentage={.3}
					borderRadius={3}
					colors={colors}
					plugins={[DashboardComparisonPluginGraphBarRefDiff()]}
				/>
			</div>
			}
			{showTable && <table className="dashboard_table">
				<thead>
					<tr>
						<th>{translate('dataset')}</th>
						<th>{translate('unit')}</th>
						<th className={'align-right'}>{translate('value')}</th>
						{objective && <th>{translate('dashboard.modal.objective')} - {objective.year}</th>}
					</tr>
				</thead>
				<tbody>
					{graphData.filter((e) => e !== null).map((e, i) => (
						<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
							<td>{e?.label}</td>
							<td>{unit}</td>
							<td>
								<span className={'number value'}>
									{resultValue(e?.values[0].value)}
									{datasetRef && datasetRef !== e?.label && <span className="number ref">
										{dashboardCompareToReference(graphData, datasetRef, e?.values[0].value ?? -1)}
									</span>}
								</span>
							</td>
							{objective && e?.values[1] && <td className={'value'}>
								{resultValue(e?.values[1].value)}
							</td>}
						</tr>
					))}
				</tbody>
			</table>}
		</div>);
};

const DashboardGlobalComparison = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);
	const [selectfootprintType, setSelectfootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();
	const [objective, setObjective] = React.useState<{ percent: number, year: number } | undefined>();
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [openObjectiveModal, setOpenObjectiveModal] = React.useState<boolean>(false);
	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);
	const [datasetRef, setDatasetRef] = React.useState<string | null>(null);

	useModal(openObjectiveModal, createDashboardComparisonObjectiveModal(
		openObjectiveModal,
		setOpenObjectiveModal,
		setObjective
	));
	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	const defineObjective = () => {
		if (objective) {
			setObjective(undefined);
		} else {
			setOpenObjectiveModal(true);
		}
	};

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	const parseDataset = (dataset: DashboardComparisonData['datasets'][number]) => {
		const value = dataset.equipments.reduce((acc, equipment) => {
			if (selectDomain !== 'all' && equipment.domain !== selectDomain) return acc;
			return acc + equipment.impacts.reduce((acc, impact) => {
				return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
			}, 0);
		}, 0);
		const indicator = indicators?.find(i => i.id === selectIndicator);
		return {
			label: dashboardLabelName({ id: dataset.id, data }),
			values: [{
				value: valueFixedDigits(parseByFootprintTypeDashboard(selectfootprintType,dataset,value)),
				scope: indicator?.unit ?? translate('results.valuePBCI') as string,
				color: '#086598'
			}]
		};
	};

	const graphData: (GroupedBarChartData | null)[] = data.datasets.map(parseDataset);
	if (objective && Number(objective.percent)) graphData.forEach(e => {
		if (e) e.values.push({
			value: valueFixedDigits(e.values[0].value * objective.percent / 100),
			scope: `${translate('dashboard.objective')} ${objective.year}`,
			color: '#46825a'
		});
	});
	if (benchmark) {
		const b = benchmark.data.datasets
			.map(d => ({ ...d, referenceFlux: benchmark.data.datasets.reduce((acc, c) => acc + c.referenceFlux, 0) }))
			.map(parseDataset);
		graphData.push({
			label: benchmark.sampleName,
			values: [{
				scope: b[0].values[0].scope,
				value: valueFixedDigits(b.reduce((acc, e) => {
					return acc + e.values[0].value;
				}, 0)),
				color: '#1a1a1a'
			}]
		});
	}
	if (graphData.length < 4) {
		graphData.unshift(null);
		graphData.push(null);
	}
	const saveData = {
		footprintType: selectfootprintType,
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		indicator: selectIndicator,
		lifeCycleStep: selectLifeCycleStep,
		domain: selectDomain,
		objective,
		graph: graphData, datasetRef,
	};

	return <div className={'dashboard_card dashboard_card_color_blue'}>
		<h3>{translate('dashboard.title.globalComparison')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectfootprintType}
				onChange={(e) => setSelectfootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectIndicator}
				onChange={(e) => setSelectIndicator(e.target.value)}
			>
				<option value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName}) - {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.name}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
			<DashboardSelectReference benchmark={benchmark} data={data} reference={datasetRef} setReference={setDatasetRef}/>
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced" />
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				<button type="button" className="button_blank dashboard_action" onClick={defineObjective}>
					<div className="dashboard_icon">
						<i className="fa-regular fa-bullseye-pointer" />
					</div>
					<div className="dashboard_text">
						{objective && translate('dashboard.action.objective.remove')}
						{!objective && translate('dashboard.action.objective.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardGlobalComparison'} />
			</div>
			<DashboardGlobalComparisonGraph showTable={showTable} data={saveData} />
		</div>
	</div>;
};

export default DashboardGlobalComparison;