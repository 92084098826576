import { gql } from '@apollo/client';

export const MUTATE_EQUIPMENT_EDIT = gql`
	mutation MUTATE_EQUIPMENT_EDIT(
		$id: String!,
		$specification: String!,
		$shortName: String!,
		$priority: Boolean!,
		$propagate: Boolean!,
		$delete: Boolean!
	) {
		mutateEquipmentEdit(
			id: $id,
			specification: $specification,
			shortName: $shortName,
			priority: $priority,
			propagate: $propagate,
			delete: $delete
		) {
			status,
			equipment {
				id,
				name,
				help,
				unit,
				priority,
				specification,
				quantity,
				quality,
				lifetime,
				internalLifetime,
				reusePart,
				reuseLifetime,
				comment,
				assigned,
				assignedCount,
				forbidden,
				equipmentTypeId,
				category,
				categoryIdentifier,
				cmdb,
				order
			}
		}
	}
`;
