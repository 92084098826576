import React, { useId, useState } from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { translate } from '../../../../infrastructure/translations/translate';
import { DashboardComparisonMaturity } from '../../../../domain/interfaces/DashboardComparisonData';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { DashboardPinButton } from '../../DashboardPin';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { getColors } from '../../../../config';
import { LineChart, LineChartData } from '../../../../viewComponents/graphs/bars/LineChart';
import { DashboardPluginGraphBarLegend, DashboardEvolutionPluginGraphBarRefDiff } from '../../DashboardPluginGraphBarLegend';
import {
	GroupedBarChart,
	GroupedBarChartData
} from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { useModal } from '../../../../viewComponents/modal/useModal';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../../Comparison/CreateDashboardComparisonBenchmarkModal';
import { TooltipItem } from 'chart.js/auto';
import { DashboardSelectReference } from '../../DashboardSelectReference';
import { compareEvolutionValueToRefValue } from '../DashboardEvolution';

type DashboardDomainsEvolutionGraphDataLine = {
	label: string;
	color?: string;
	dashed?: boolean;
	values: {
		scope: string;
		value: number;
		color?: string;
	}[];
}

export interface DashboardMaturityDomainEvolutionGraphData {
	graph: (DashboardDomainsEvolutionGraphDataLine | null)[],
	reference: string | null
}

const options: ChartProps<'line' | 'bar'>['options'] = {
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'line' | 'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		},
		y: {
			max: 5,
		}
	}
};

export const DashboardMaturityDomainEvolutionGraph = ({ showTable, data, histogram }: { showTable: boolean, data: DashboardMaturityDomainEvolutionGraphData, histogram: boolean }) => {
	const { graph: graphData, reference } = data;
	const id = useId();
	const years = graphData
		.filter(e => e)
		.map(e => e?.values.map(v => v.scope))
		.flat()
		.filter(unique)
		.sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	const graphLine: (LineChartData | null)[] = years.map((year) => ({
		label: year ?? '',
		values: graphData
			.map(d => {
				const find = d?.values.find(v => v.scope === year);
				return {
					scope: d?.label ?? '',
					value: find?.value ?? null,
					color: d?.color ?? 'black',
					dashed: d?.dashed ?? false
				};
			})
	}));

	graphLine.unshift(null);
	graphLine.push(null);
	if (graphLine.length < 4) {
		graphLine.unshift(null);
		graphLine.push(null);
	}

	const graphBar: (GroupedBarChartData | null)[] = graphData.map(d => d ? ({
		label: d.label,
		values: years
			.filter(y => d.values.find(v => v.scope === y))
			.map((y) => ({
				scope: y ?? '',
				value: d.values.find(v => v.scope === y)?.value ?? 0,
				color: d.values.find(v => v.scope === y)?.color ?? 'black'
			}))
	}) : null);

	return (<div className="dashboard_graph">
		{!showTable && <>
			<div className="dashboard_chart_container">
				{!histogram && <LineChart
					data={graphLine}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id)]}
				/>}
				{histogram && <GroupedBarChart
					data={graphBar}
					datasetRef={reference}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id), DashboardEvolutionPluginGraphBarRefDiff(true)]}
					thin={15}
					percentage={1}
					categoryPercentage={.5}
					borderRadius={3}
				/>}
			</div>
			<div id={id} className="dashboard_graph_legend"/>
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					{years.map((year, i) => (
						<th key={i}>{year}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{graphData
					.filter(d => d)
					.map((d, i) => (<tr key={i}>
						<td>{d?.label}</td>
						{years.map((year, j) => {
							const find = d?.values.find(d => d.scope === year);
							const refValue = d?.values.find(v => v.scope === reference)?.value;
							return (
								<td style={{ fontWeight: reference === year ? 'bold' : 'normal' }} key={j}>
									<span className={'number value'}>
										{resultValue(find?.value)}
										{reference && year !== reference &&
                                            <span className="number ref">{compareEvolutionValueToRefValue(refValue, find?.value)}</span>}
									</span>
								</td>
							);
						})}
					</tr>))}
			</tbody>
		</table>}
	</div>);
};

const DashboardMaturityDomainEvolution = ({
	data
}: {
	data: DashboardComparisonMaturity
}): JSX.Element => {
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();

	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [histogram, setShowHistogram] = React.useState<boolean>(false);
	const [reference, setReference] = React.useState<string | null>(null);
	const isLineMode = !histogram && !showTable;

	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);

	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	const DifferentStudyNames = data.datasets.map(d => d.study).filter(unique).length > 1;
	const DifferentCompanyNames = data.datasets.map(d => d.company).filter(unique).length > 1;
	const getName = (d: {
		name?: string,
		study?: string,
		company?: string
	}): string => {
		let name = '';
		if (DifferentCompanyNames) name = ` - ${d.company}`;
		if (DifferentStudyNames) name = ` - ${d.study}` + name;
		return d.name + name;
	};
	const datasetNames = data.datasets.map(d => getName(d)).filter(unique);
	const [selectDatasetName, setSelectDatasetName] = useState<string>(datasetNames[0]);

	const colors = getColors(data.domains.length, true);
	const domainColors = data.domains.map((domain, i) => ({
		id: domain,
		color: colors[i]
	}));

	const selectedDomains = selectDomain === 'all' ? data.domains : [selectDomain];
	const selectedDatasets: DashboardComparisonMaturity['datasets'] = data.datasets.filter(d => getName(d) === selectDatasetName);
	const years = selectedDatasets.map(d => d.year).filter(unique).sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	const yearColorsList = getColors(years.length, true);
	const yearColors = years.map((y, i) => { return { year: y, color: yearColorsList[i] }; });

	const parseDatasetValue = (filter: string, dataset: DashboardComparisonMaturity['datasets'][number]): number => {
		const filtered = dataset.maturity.filter(m => m.domain === filter);
		if (!filtered.length) return 0;
		const value = filtered.reduce((acc, m) => acc + m.grade, 0);
		return value / filtered.length;
	};

	let graphData: (DashboardDomainsEvolutionGraphDataLine | null)[] = [];
	if (selectDomain !== 'average')
		graphData = (selectedDomains)
			.filter(e => selectDomain !== 'all' && selectDomain !== 'average' || (
				selectedDatasets.find(d => d.maturity.find(m => m.domain === e))
			)).map(filter => ({
				label: filter,
				color: (domainColors).find(e => e.id === filter)?.color || 'black',
				values: years
					.filter(y => selectedDatasets.find(d => d.year === y))
					.map(y => {
						const dataset = selectedDatasets.find(d => d.year === y);
						return {
							scope: y,
							value: dataset ? parseDatasetValue(filter, dataset) : 0,
							color: yearColors.find(e => e.year === y)?.color || 'black'
						};
					})
			}));
	if (selectDomain == 'all' || selectDomain == 'average') {
		graphData.push({
			label: translate('average') as string,
			color: selectDomain === 'all' ? 'black' : domainColors[0].color,
			values: years
				.filter(y => selectedDatasets.find(d => d.year === y))
				.map(y => {
					const ds = selectedDatasets.filter(d => d.year === y);
					const value = ds.reduce(
						(acc, d) => acc + (d.maturity.reduce(
							(a, m) => {
								return a + m.grade;
							}, 0) / d.maturity.length),
						0) / ds.length;
					return {
						scope: y,
						value: value,
						color: yearColors.find(e => e.year === y)?.color || 'black'
					};
				})
		});
	}
	if (benchmark) {
		graphData.push({
			label: benchmark.sampleName,
			color: 'black',
			dashed: true,
			values: years
				.filter(y => benchmark.maturity.datasets.find(d => d.year === y))
				.map(y => {
					const ds = benchmark.maturity.datasets.filter(d => d.year === y);
					const value = ds.reduce(
						(acc, d) => acc + (d.maturity.reduce(
							(a, m) => {
								if (selectDomain !== 'all' && !selectedDomains.includes(m.domain)) return a;
								return a + m.grade;
							}, 0) / d.maturity.filter(m => selectDomain !== 'all' && selectedDomains.includes(m.domain)).length),
						0) / ds.length; 
					return {
						scope: y,
						value
					};
				})
		});
	}


	const saveData = {
		domain: selectDomain,
		dataset: selectDatasetName,
		graph: graphData,
		reference: reference
	};
	return <div className={'dashboard_card dashboard_card_color_yellow'}>
		<h3>{translate('dashboard.title.maturityDomainsEvolution')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectDatasetName}
				onChange={(e) => setSelectDatasetName(e.target.value)}
			>
				{datasetNames.map((d, i) => (
					<option key={i} value={d}>{d}</option>
				))}
			</select>
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{data.domains.map((domain) => (
					<option key={domain} value={domain}>{domain}</option>
				))}
				<option value={'average'}>{translate('dashboard.select.allDomainsAverage')}</option>
			</select>
			{!isLineMode && <DashboardSelectReference benchmark={benchmark} data={data} reference={reference} setReference={setReference} isEvolution={true}/>}
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced" />
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowHistogram(!histogram)}
				>
					<div className="dashboard_icon">
						{histogram && <i className="fa-solid fa-chart-column"/>}
						{!histogram && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{histogram && translate('dashboard.action.histogram.hide')}
						{!histogram && translate('dashboard.action.histogram.show')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column"/>}
						{!showTable && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardMaturityDomainEvolution'}/>
			</div>
			<DashboardMaturityDomainEvolutionGraph showTable={showTable} data={saveData} histogram={histogram}/>
		</div>
	</div>;
};

export default DashboardMaturityDomainEvolution;