export const localStorageGetToken = (): Promise<string> => {
	const token = localStorage.getItem('token') || '';
	return Promise.resolve(token);
};

export const localStorageSetToken = (token: string): Promise<boolean> => {
	localStorage.setItem('token', token);
	return Promise.resolve(true);
};

export const localStorageGetLang = (): string => {
	return localStorage.getItem('lang') || navigator.language || 'en';
};

export const localStorageSetLang = (lang: string): Promise<boolean> => {
	localStorage.setItem('lang', lang);
	return Promise.resolve(true);
};
