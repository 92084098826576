/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import merge from 'lodash.merge';
import { cutLabels } from '../utils/cutLabels';
import { translate } from '../../../infrastructure/translations/translate';

export interface ReducedBarChartDataValue {
	value: number;
	scope: string;
	color: string;
}
export interface ReducedBarChartData {
	label: string;
	values: [ReducedBarChartDataValue, ReducedBarChartDataValue | null];
}

/**
 * Stacked Bar Chart
 * @param data
 * @param options
 * @param plugins
 * @param thin
 * @param percentage
 * @param categoryPercentage
 * @param borderRadius
 * @param borderWidth
 * @constructor
 *
 * @author Maximilien Valenzano
 */
export const ReducedBarChart = ({ data, options, plugins, thin, percentage, categoryPercentage, borderRadius, borderWidth }: {
	data: (ReducedBarChartData | null)[];
	options?: ChartProps<'bar'>['options'];
	plugins?: ChartProps<'bar'>['plugins'];
	thin?: number;
	percentage?: number;
	categoryPercentage?: number;
	borderRadius?: number;
	borderWidth?: number;
}): JSX.Element => {
	const [chartKey, setChartKey] = React.useState<number>(0);
	const [timeOutKey, setTimeOutKey] = React.useState<ReturnType<typeof setTimeout>>();
	const labels = data.map(d => d && cutLabels(d.label));
	const refValue = data.filter(d => d != null)[0]?.values[0].value ?? 0;
	const datasets = [
		{
			label: data[0]?.values[0].scope,
			data: data.map((d) => d?.values[0].value ?? null),
			backgroundColor: data.map(d => d?.values[0].color),
			borderColor: 'transparent'
		},
		{
			label: data[0]?.values[1]?.scope,
			data: data.map((d) => d?.values[1]?.value ?? null),
			backgroundColor: data.map(d => d?.values[1]?.color),
			borderColor: 'transparent'
		}
	];
	const barData: ChartProps<'bar'>['data'] = {
		labels,
		datasets
	};

	if (thin !== undefined) barData.datasets.forEach(d => {
		d.maxBarThickness = thin;
	});
	if (percentage !== undefined) barData.datasets.forEach(d => {
		d.barPercentage = percentage;
	});
	if (categoryPercentage !== undefined) barData.datasets.forEach(d => {
		d.categoryPercentage = categoryPercentage;
	});
	if (borderRadius !== undefined) barData.datasets.forEach(d => {
		d.borderRadius = borderRadius;
	});
	if (borderWidth !== undefined) barData.datasets.forEach(d => {
		d.borderWidth = borderWidth;
	});

	options = merge({
		scales: {
			y: {
				beginAtZero: true,
				stacked: true,
			},
			x: {
				beginAtZero: true,
				stacked: true,
				ticks: {
					autoSkip: false,
					maxRotation: 0,
				}
			}
		}
	}, options ?? {});

	const drawText = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number, color: string) => {
		ctx.fillStyle = color;
		ctx.font = 'bold 14px Arial';
		ctx.fillText(text, x - (ctx.measureText(text).width / 2), y);
	};

	const drawArrow = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, color: string, over: boolean) => {
		ctx.save();
		ctx.fillStyle = color;

		const radius = 5;
		const arrowWidth = width * 0.5 + 15;
		const arrowHeight = 12;

		const startY = over ? y + 6 : y + height - 6;

		ctx.beginPath();
		if (over) {
			ctx.moveTo(x, startY);
			ctx.lineTo(x + arrowWidth - radius, startY);
			ctx.arcTo(x + arrowWidth, startY, x + arrowWidth - radius, startY - radius * 0.5, radius * 0.5);
			ctx.lineTo(x, startY - arrowHeight);
			ctx.moveTo(x, startY);
			ctx.lineTo(x - arrowWidth + radius, startY);
			ctx.arcTo(x - arrowWidth, startY, x - arrowWidth + radius, startY - radius * 0.5, radius * 0.5);
			ctx.lineTo(x, startY - arrowHeight);
		} else {
			ctx.moveTo(x, startY);
			ctx.lineTo(x + arrowWidth - radius, startY);
			ctx.arcTo(x + arrowWidth, startY, x + arrowWidth - radius, startY + radius * 0.5, radius * 0.5);
			ctx.lineTo(x, startY + arrowHeight);
			ctx.moveTo(x, startY);
			ctx.lineTo(x - arrowWidth + radius, startY);
			ctx.arcTo(x - arrowWidth, startY, x - arrowWidth + radius, startY + radius * 0.5, radius * 0.5);
			ctx.lineTo(x, startY + arrowHeight);
		}
		ctx.closePath();
		ctx.fill();
		ctx.restore();
	};

	const graphPlugins: ChartProps<'bar'>['plugins'] = [
		{
			id: 'ArrowOnTopOfBar',
			afterDatasetsDraw: (chart: any) => {
				const dataset1 = chart.data.datasets[0];
				const dataset2 = chart.data.datasets[1];
				const meta = chart.getDatasetMeta(1);
				let lastData = refValue;
				meta.data.forEach((bar: any, index: number) => {
					const datab = dataset2.data[index];
					if (datab === null) return;
					const total = dataset1.data[index] + dataset2.data[index];
					const x = bar.x;
					const y = bar.y;
					const width = bar.width;
					const height = bar.height;
					const color = dataset2.backgroundColor[index];
					const isOver = total > refValue;
					const text = `${isOver ? '+' : '-'}${(datab * 100 / refValue).toFixed(0)}%`;
					drawText(chart.ctx, text, x, y + height + (isOver ? 14 : 19), color);
					drawArrow(chart.ctx, x, y, width, height, color, isOver);
				});
				const metaObjective = chart.getDatasetMeta(0);
				const index = metaObjective.data.reduce((p: number, c: any, i: number) => dataset1.data[i] == null ? p : i, 0);
				if (dataset2.data[index] != null) return;
				const bar = metaObjective.data[index];
				const data = dataset1.data[index];
				lastData = dataset2.data[index - 1];
				const total = dataset1.data[index - 1] + dataset2.data[index - 1];
				const isOver = total > refValue;
				const lastBar = meta.data[index - 1];
				const percent = (((isOver ? total : total - lastData) - data) / refValue) * 100;
				const text = `${translate('to.be.done.abrev')}: ${percent>0?'-':''}${percent.toFixed(0)}%`;
				drawText(chart.ctx, text, bar.x + bar.width, bar.y - 5, '#46825a');
				const ctx = chart.ctx;
				ctx.save();
				ctx.beginPath();
				const y = lastBar.y + (isOver ? -5 : lastBar.height + 5);
				ctx.moveTo(lastBar.x, y);
				ctx.lineTo(bar.x, y);
				ctx.lineWidth = 2;
				ctx.setLineDash([5, 5]);
				ctx.strokeStyle = '#46825a';
				ctx.stroke();
				ctx.closePath();
				ctx.save();
				ctx.beginPath();
				ctx.moveTo(bar.x, y);
				ctx.lineTo(bar.x, bar.y);
				ctx.lineWidth = 1;
				ctx.strokeStyle = '#46825a';
				ctx.setLineDash([]);
				ctx.stroke();
				ctx.closePath();
				ctx.save();
				if (Math.abs(y - bar.y) > 10) {
					ctx.beginPath();
					ctx.moveTo(bar.x, y);
					ctx.lineTo(bar.x - 3, y + 5);
					ctx.lineTo(bar.x + 3, y + 5);
					ctx.closePath();
					ctx.fillStyle = '#46825a';
					ctx.fill();
					ctx.closePath();
					ctx.save();
					ctx.beginPath();
					ctx.moveTo(bar.x, bar.y);
					ctx.lineTo(bar.x - 3, bar.y - 5);
					ctx.lineTo(bar.x + 3, bar.y - 5);
					ctx.closePath();
					ctx.fillStyle = '#46825a';
					ctx.fill();
				}
			},
		},
		...plugins ?? []
	];

	const resize = () => {
		clearTimeout(timeOutKey);
		const t = setTimeout(() => {
			setChartKey(n => n + 1);
		}, 100);
		setTimeOutKey(t);
	};

	React.useEffect(() => {
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [timeOutKey]);

	React.useEffect(() => {
		resize();
	}, [JSON.stringify(data)]);

	return (
		<Bar
			className='dynamic_chart'
			data={barData}
			options={options}
			plugins={graphPlugins}
			key={chartKey}
		/>
	);
};