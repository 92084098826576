import React, { useEffect, useState } from 'react';
import { IEquipmentsVersions } from '../../../domain/interfaces/IQueryLauncherEquipmentsVersions';
import { translate } from '../../../infrastructure/translations/translate';
import { ResultCardError } from '../../error/ResultCardError/ResultCardError';
import './EquipmentImpactVersionModal.css';
import Modal from '../Modal';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';
import { useTooltip } from '../../tip/useTooltip';

/**
 * @description Modal that displays the equipment versions of a result launcher
 * @param resultLauncherId
 * @param onClose  
 * @returns JSX.Element
 * 
 *  @auth Yacine Bentayeb
 */
export const ResultEquipmentImpactVersion = ({ resultLauncherId, onClose }: { resultLauncherId: string, onClose: () => void }): JSX.Element => {
	const { entity: rlEntity } = useResultLaunchers();
	const [equipmentVersions, setEquipmentVersions] = useState<IEquipmentsVersions[]>([]);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	useTooltip(showTooltip, translate('results.tooltip.dataReference'));

	useEffect(() => {
		rlEntity?.queryLauncherEquipmentsVersions({ resultLauncherId: resultLauncherId }).then(
			(result) => {
				setEquipmentVersions(result.launcherEquipmentsVersions);
			}
		);
	}, []);

	const formateDate = (date: string): string => {
		const dateObj = new Date(date);
		return dateObj.toLocaleDateString();
	};

	if (equipmentVersions.length <= 0) {
		return (<ResultCardError/>);
	}

	return (
		<Modal onClose={onClose}>
			<div className={'modal_content version_table_container'}>
				<h1 className={'version_table_title'}>
					{translate('results.equipmentVersions')}
				</h1>
				<div className={'version_table_wrapper'}>
					<table className={'result_table version_table'}>
						<thead>
							<tr>
								<th>{translate('results.equipment')}</th>
								<th>{translate('results.equipmentShortNameAndSpec')}</th>
								<th>{translate('results.version')}</th>
								<th>
									{translate('results.dataReference')}
									<i className="fa-regular fa-info-circle" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}/>
								</th>
								<th>{translate('results.dateVersion')}</th>
								<th>{translate('results.dbSource')}</th>
							</tr>
						</thead>
						<tbody>
							{equipmentVersions.map((equipment, idx) => {
								return (<tr key={idx}>
									<td>{equipment.equipmentTypeName}</td>
									<td>{equipment.equipmentTypeShortNameAndSpecification}</td>
									<td>{equipment.version}</td>
									<td className={'data_reference'}>
										{equipment.dataReference.reference.length > 0 && equipment.dataReference.isHash &&
											<a href={`https://db.resilio.tech/audit/${equipment.dataReference.reference}`} aria-label={translate('results.redirect.id') as string}>
												{equipment.dataReference.reference}
											</a>}
										{equipment.dataReference.reference.length > 0 && !equipment.dataReference.isHash &&
											<span>{equipment.dataReference.reference}</span>}
										{equipment.dataReference.reference.length <= 0 && <span>-</span>}
									</td>
									<td>{formateDate(equipment.updatedAt)}</td>
									<td>{equipment.equipmentTypeSource}</td>
								</tr>);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Modal>
	);
};
