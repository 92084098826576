import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { Mode, ModeSelectionButton, studyParameterModes } from '../ModeSelection/ModeSelectionButton';
import { IQueryColorsOutput } from '../../../domain/interfaces/IQueryColors';
import { ILifeCycleStep } from '../../../domain/interfaces/IQueryInventoryExtraContent';
import { IIndicator } from '../../../domain/interfaces/IIndicator';
import { IBlock } from '../../../domain/interfaces/IBlock';
import { pickColorByIndex, GRAPH_COLORS } from '../../../config';
import { closeGlobalModal } from '../GlobalModal';
import './StudyColorSettingsModal.scss';
import { Alert } from '../../Alerts';
import { useBlocks } from '../../hooks/useBlocks';
import { useLifeCycleSteps } from '../../hooks/useLifeCycleSteps';
import { useIndicators } from '../../hooks/useIndicators';
import { useStudies } from '../../hooks/useStudies';

/**
 * 
 * @param {string} name
 * @param {('indicators' | 'blocks' | 'lifeCycleSteps')} mode
 * @param {string} color
 * @param {string} id
 * @param {(id: string, mode: 'indicators' | 'blocks' | 'lifeCycleSteps', color: string) => void} setColor
 * @description This component is used to display a line in the modal to update the colors of the study
 * @see name is the name of the entity (block, indicator or life cycle step)
 * @see mode is the mode of the modal (block, indicator or life cycle step)
 * @see color is the color of the entity
 * @see id is the id of the item
 * @returns {JSX.Element}
 * 
 * @author Yacine Bentayeb
 */
const ColorSelectionLine = ({ name, mode , color, id, setColor }: {
	name: string,
	mode: 'indicators' | 'blocks' | 'lifeCycleSteps',
	color: string,
	id: string,
	setColor: (id: string, mode: 'indicators' | 'blocks' | 'lifeCycleSteps', color: string) => void,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [mousePosition, setMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
	const colorRef = useRef<string>('');
	const listColors = Object.values(GRAPH_COLORS);
	const chooseColorRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (chooseColorRef.current && !chooseColorRef.current.contains(event.target as Node)) {
			if (colorRef.current !== color)
				setColor(id, mode, colorRef.current);
			setOpen(false);
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	return (
		<div className='color_line' key={id} role='listitem' aria-label='Color item'>
			<p>{name}</p>
			<div onClick={(e) =>{ 
				setOpen(!open);
				colorRef.current = color;
				setMousePosition({ x: e.clientX, y: e.clientY });
			}}
			className='color_selector'
			style={{ backgroundColor: color }}>
				{open &&
				<div className='color_container' ref={chooseColorRef} style={{ top: mousePosition.y + 10, left: mousePosition.x - 100 }}>
					<div className='color_palette'>
						{listColors.map(itemColor =>
							<div
								key={itemColor}
								className={`color_palette_item ${itemColor === color ? 'selected' : ''}`}
								style={{ backgroundColor: itemColor }}
								onClick={() => setColor(id, mode, itemColor)}
							/>
						)}
					</div>
					<div className='choose_color' onBlur={() => setOpen(false)} aria-label='Choose a color'>
						<input 
							onClick={e => e.stopPropagation()}
							type='color' 
							value={color} 
							onChange={e => {
								colorRef.current = e.target.value;
							}}
							onBlur={() => setColor(id, mode, colorRef.current)}
							className='color_input'
						/>
						<p>{translate('userManage.studies.colorSettings.chooseColor') as string}</p>
						<i className='fa-regular fa-palette'/>
					</div>
				</div>}
			</div>
		</div>
	);
};

/**
 * @param studyId string
 * @param openModal boolean
 * @param setOpenModal (bool: boolean) => void
 * @param colors IQueryColorsOutput | null
 * @description This hook is used to display the modal to update or set the colors of the study
 * @see setGlobalModal is used to store an IModal instance in the store
 * @see IModal is the interface of the modal
 * @see GlobalModal is the component that will be displayed
 * @author Yacine Bentayeb
 */
export const createStudyColorSettingsModal = (
	studyId: string,
	openModal: boolean,
	setOpenModal: (bool: boolean) => void,
	colors: IQueryColorsOutput | null,
) => {
	const { indicators } = useIndicators();
	const { lcs } = useLifeCycleSteps();
	const { blocks } = useBlocks();
	const { entity: studiesEntity } = useStudies();

	const [selectedMode, setSelectedMode] = useState<Mode>(translate('userManage.studies.colorSettings.blockMode') as string);
	const [selectedColors, setSelectedColors] = useState<IQueryColorsOutput | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [hasDefaultColor, setHasDefaultColor] = useState<boolean>(false);

	if (!openModal) return;

	const modeBlocks = selectedMode === studyParameterModes[0];
	const modeIndicators = selectedMode === studyParameterModes[1];
	const modeLCS = selectedMode === studyParameterModes[2];

	// Filter the blocks to only display the blocks that are related to the study
	const filteredBlocks: IBlock[] = blocks
		.filter((block: IBlock) => block.studyIds && block.studyIds.includes(studyId))
		.map((block: IBlock, index: number) => {
			return { ...block, order: index };
		});

	// Initialize the colors displayed in the modal, we pick first the color from the backend if it exists
	// Else we pick in order the colors from the config file
	if (colors && !selectedColors) {
		const newColors = {
			colors: {
				blocks: filteredBlocks?.map((block: IBlock) => {
					let color = colors?.colors.blocks.find(item => item.itemId === block.id)?.color;
					if (!color) {
						color = pickColorByIndex(block.order);
						setHasDefaultColor(true);
					}
					return { itemId: block.id, color: color };
				}) ?? [],
				indicators: indicators?.map((indicator: IIndicator) => {
					let color = colors?.colors.indicators.find(item => item.itemId === indicator.id)?.color;
					if (!color) {
						color = pickColorByIndex(indicator.order);
						setHasDefaultColor(true);
					}
					return { itemId: indicator.id, color: color };
				}) ?? [],
				lifeCycleSteps: lcs?.map((l: ILifeCycleStep) => {
					let color = colors?.colors.lifeCycleSteps.find(item => item.itemId === l.id)?.color;
					if (!color) {
						color = pickColorByIndex(l.order);
						setHasDefaultColor(true);
					}
					return { itemId: l.id, color: color };
				}) ?? [],
			},
		};
		setSelectedColors(newColors);
	}

	// Add or update a color to the selectedColors state
	const setColor = (id: string, mode: 'indicators' | 'blocks' | 'lifeCycleSteps', color: string) => {
		if (!selectedColors) return;

		const newColors = {
			...selectedColors?.colors,
			[mode]: selectedColors?.colors[mode].map(entity => {
				return entity.itemId === id ? { ...entity, color } : entity;
			}) ?? [],
		};
		setSelectedColors({ colors: newColors });
	};

	// Find a color in a IQueryColorsOutput object depending on the mode
	const findColor = (id: string, colorsToSearch: IQueryColorsOutput | null) => {
		let color = null;
		if (modeLCS)
			color = colorsToSearch?.colors.lifeCycleSteps.find(item => item.itemId === id)?.color;
		if (modeBlocks)
			color = colorsToSearch?.colors.blocks.find(item => item.itemId === id)?.color;
		if (modeIndicators)
			color = colorsToSearch?.colors.indicators.find(item => item.itemId === id)?.color;
		if (color) return color;
		return null;
	};

	// Choose the color to display in the modal, if the color is not found in the selectedColors state
	// We look in the colors state (the colors from the store)
	const chooseDefaultOrSelectedColor = (id: string) => {
		let color = null;
		if (selectedColors) {
			color = findColor(id, selectedColors);
			if (color) return color;
		}
		if (colors) {
			color = findColor(id, colors);
			if (color) return color;
		}
		return '#000000';
	};

	// Save the colors in the backend
	const saveColors = () => {
		if (!selectedColors) return;
		setLoading(true);
		const jsonColors = JSON.stringify(selectedColors?.colors);
		studiesEntity?.mutateColorManagement({ colors: jsonColors, studyId: studyId })
			.then(() => {
				setLoading(false);
				closeGlobalModal();
				setOpenModal(false);
			}).catch(() => {
				setLoading(false);
				setErrorMessage(translate('userManage.studies.colorSettings.error') as string);
			});
	};

	const body =
		<div className={'modal_body'} role='dialog' aria-modal='true' aria-labelledby='modal_title'>
			<div className='color_settings'>
				<div className='modes_buttons' role='tablist' aria-label='Modes'>
					{Object.values(studyParameterModes).map(mode =>
						<ModeSelectionButton
							key={mode.toString()}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							mode={mode}
						/>
					)}
				</div>
				<hr />
				<Alert variant={'info'}>
					<i className={'m2 fas fa-exclamation-triangle'}/>
					{translate(`userManage.studies.colorSettings.${hasDefaultColor ? 'warningDefaultColor' : 'warningSavedColor'}`)}
				</Alert>
				<div className='colors_selection' role='list' aria-label='Colors list'>
					{modeLCS && lcs?.map((lcs: ILifeCycleStep) =>
						<ColorSelectionLine
							key={lcs.id}
							name={lcs.name}
							mode={'lifeCycleSteps'}
							color={chooseDefaultOrSelectedColor(lcs.id)}
							id={lcs.id}
							setColor={setColor}
						/>
					)}
					{modeBlocks && filteredBlocks?.map((block: IBlock) => {
						return (<ColorSelectionLine
							key={block.id}
							name={block.name}
							mode={'blocks'}
							color={chooseDefaultOrSelectedColor(block.id)}
							id={block.id}
							setColor={setColor}
						/>);
					}
					)}
					{modeIndicators && indicators?.map((indicator: IIndicator) =>
						<ColorSelectionLine
							key={indicator.id}
							name={indicator.name}
							mode={'indicators'}
							color={chooseDefaultOrSelectedColor(indicator.id)}
							id={indicator.id}
							setColor={setColor}
						/>
					)}
				</div>
			</div>
		</div>;

	return {
		visible: true,
		header: translate('userManage.studies.colorSettings.title') as string,
		body: body,
		onClose: () => setOpenModal(false),
		action: () => saveColors(),
		load: loading,
		error: errorMessage?.length > 0,
		errorMessage: errorMessage,
	};
};