import React from 'react';
import { CORP_COLORS, getColorByScore } from '../../config';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { ResultTable } from './ResultTable';
import { DoughnutPluginWritePercentage } from '../graphs/plugins/DoughnutPluginWritePercentage';
import { RelativeDoughnutChart } from '../graphs/doughnuts/RelativeDoughnutChart';

export type ResultQualityOverviewResultType = { data: {
	quality: string; value: number;
}[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultQualityOverviewResult = (o: any): o is ResultQualityOverviewResultType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};

// dictionnary of quality from 1 to 3
const qualities = [
	translate('results.quality.none'),
	translate('results.quality.low'),
	translate('results.quality.medium'),
	translate('results.quality.high')
];

export const ResultQualityOverview = ({ data }: { data: string }): JSX.Element => {
	if (!isResultQualityOverviewResult(data)) return (<ResultCardError/>);
	const resultData = data.data;

	const dataTable = {
		columns: [
			{ name: translate('results.quality') as string },
			{ name: translate('results.value') as string },
		],
		values: resultData.map((d) => [
			{ value: qualities[Number(d.quality)], valueType: 'left' },
			{ value: `${resultValue(d.value as number)}%`, valueType: 'right' },
		])
	};

	return <ResultTable dataTable={dataTable} />;
};

export const ResultQualityOverviewGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultQualityOverviewResult(data)) return (<ResultCardError/>);
	const resultData = data.data;
	if (!resultData) return <ResultCardError/>;

	const dataset = resultData.map((d) => ({
		labels: qualities[Number(d.quality)] as string,
		values: (d.value),
		color: Number(d.quality) === 0 ? CORP_COLORS.gray :
			getColorByScore(Number(d.quality))
	}));

	return <RelativeDoughnutChart
		datasets={dataset}
		plugins={[DoughnutPluginWritePercentage()]}
		title={'test'}
	/>;
};
