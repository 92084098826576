import { gql } from '@apollo/client';

export const MUTATE_COMPANY_TO_MANAGE = gql`
    mutation MutateCompanyToManage(
    	$userId: String!
    	$companyId: String!
    	$manage: Boolean!
    ){
    	mutateCompanyToManage(
    		userId: $userId
    		companyId: $companyId
    		manage: $manage
		){
			status
			userList {
				id,
				username,
				email,
				phoneNumber,
				groups {
					id,
					name,
					translate
					permissions
					grade
					privileged
					manageSameGrade
                }
                permissions
				myCompany {
					id,
					name,
					emailDomains,
					isCguAccepted
				},
				datasets {
					id
				},
                blockModify,
				isActive,
				companiesToManage {
					id,
					name,
					emailDomains,
					isCguAccepted
				}
			}
		}
    }
`;
