import React, { useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import ChoiceSelector from '../../head/choiceSelector/choiceSelector';
import { ISampleListItem } from '../../../domain/interfaces/ISample';
import { closeGlobalModal } from '../GlobalModal';
import './ResultComparisonModal.scss';


export const useSampleResultComparison = (
	openModal: boolean,
	resultsIds: string[],
	setOpenModal: (bool: boolean) => void,
	sampleList: ISampleListItem[],
	onSampleChange: (resultIds: string[], sampleId: string) => void,
	loading: boolean,
	setTooltip: (text: string | null) => void,
) => {
	const [sample, setSample] = useState<string>('');

	if (!openModal) return;

	const onClose = () => {
		setOpenModal(false);
		closeGlobalModal();
	};

	const choose = () => {
		onSampleChange(resultsIds, sample);
	};

	const body = 
	<div className='comparison_modal_content'>
		<ChoiceSelector
			selectorName={'select_sample'}
			list={sampleList.map((sample) => ({
				component: <>
					{sample.name}
					<i
						onMouseEnter={() => setTooltip(sample.desc)}
						onMouseLeave={() => setTooltip(null)}
						className={'fa-regular fa-info-circle'}
						style={{ marginLeft: '5px' }}
					/>
				</>,
				value: sample.id,
				name: sample.name 
			}))}
			selected={sample}
			handle={setSample}
		/>
	</div>;


	return {
		action: choose,
		visible: true,
		body: body,
		header: translate('results.compare') as string,
		onClose: onClose,
		load: loading,
	};
};