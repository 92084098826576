import { gql } from '@apollo/client';

export const MUTATE_CHECK_COMPUTE_STATUS = gql`
    mutation MutateCheckComputeStatus(
        $datasetsIds: [String!]!,
        $resultList: String,
        $resultTypesIdentifiers: [Int!]
    ){
        mutateCheckComputeStatus(
            datasetsIds: $datasetsIds,
            resultList: $resultList,
            resultTypesIdentifiers: $resultTypesIdentifiers
        ){
            status,
            errors {
                code,
                info,
            }
        }
    }
`; 