import { gql } from '@apollo/client';

export const MUTATE_ADD_EQUIPMENT_RESILIO_DB = gql`
    mutation MutateAddEquipmentResilioDb(
    		$datasetId: String!,
    		$blockId: String!,
     		$category: String!,
			$data: String!,
			$toStudy: Boolean!,
     	){
    	mutateAddEquipmentResilioDb(
    		datasetId: $datasetId,
    		blockId: $blockId,
    		category: $category,
			toStudy: $toStudy,
			data: $data,
    	){
    		status
    		data {
				id,
				name,
				shortName,
				equipments {
					id,
					name,
					help,
					unit,
					unitShortName,
					priority,
					specification,
					quantity,
					quality,
					unknown
					lifetime,
					internalLifetime,
					reusePart,
					reuseLifetime,
					customElectricityConsumption,
					comment,
					assigned,
					assignedCount,
					forbidden,
					equipmentTypeId,
					flag,
					category,
					categoryIdentifier,
					cmdb,
					defaultLifetime
				},
				cmdb {
					id,
            		name,
            		category,
            		comment,
            		equipmentsInfo {
            			category,
            			quantity
            			totalLifetime,
            			numberOfType
					}
				},
				metadata {
					equipmentRate
				},
				priority
    		}
    		errors
    	}
    }
`;
