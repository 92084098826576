import { ILambdaUser, IUser, ResponseCompanyUsers } from '../interfaces/ResponseCompanyUsers';
import { Entity } from '../core/Entity';
import {
	ApolloClientMutateCompanyManagement,
	ApolloClientMutateCompanyToManage,
	ApolloClientQueryCompaniesManagement,
	ApolloClientQueryCompanyUsers,
	ApolloClientQuerySsoSettings
} from '../../infrastructure/ApolloClass/CompanyClass';
import { MUTATE_COMPANY_MANAGE } from '../../infrastructure/ApolloClient/requests/MUTATE_COMPANY_MANAGE';
import { MUTATE_COMPANY_TO_MANAGE } from '../../infrastructure/ApolloClient/requests/MUTATE_COMPANY_TO_MANAGE';
import { QUERY_COMPANIES_TO_MANAGE } from '../../infrastructure/ApolloClient/requests/QUERY_COMPANIES_TO_MANAGE';
import { QUERY_COMPANY_USERS } from '../../infrastructure/ApolloClient/requests/QUERY_COMPANY_USERS';
import { ICompanyManage } from '../data/entries/ICompanyManage';
import { IMutateCompanyToManageInput } from '../interfaces/IMutateCompanyToManage';
import { QUERY_SSO_SETTINGS } from '../../infrastructure/ApolloClient/requests/QUERY_SSO_SETTINGS';
import { IQuerySsoSettingsInput, IQuerySsoSettingsOutput } from '../interfaces/IQuerySsoSettings';

export type CompanyData = ResponseCompanyUsers['myCompany'];

export class CompanyEntity extends Entity {
	public data: CompanyData | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateCompanyManagement ??= this.app.installer(ApolloClientMutateCompanyManagement, MUTATE_COMPANY_MANAGE);
		this.app.adapter.mutateCompanyToManage ??= this.app.installer(ApolloClientMutateCompanyToManage, MUTATE_COMPANY_TO_MANAGE);
		this.app.adapter.queryCompaniesManagement ??= this.app.installer(ApolloClientQueryCompaniesManagement, QUERY_COMPANIES_TO_MANAGE);
		this.app.adapter.fetchCompanyUsers ??= this.app.installer(ApolloClientQueryCompanyUsers, QUERY_COMPANY_USERS);
		this.app.adapter.querySsoSettings ??= this.app.installer(ApolloClientQuerySsoSettings, QUERY_SSO_SETTINGS);
		this.app.adapter.storeCompanyDTO?.({ error: null, data: this, loading: false });
	}

	get(): CompanyData | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.app.adapter.storeCompanyDTO?.({ data: this, error: null, loading: true });
			this.queryCompanyUsers();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeCompanyDTO?.({ data: this, error: null, loading: false });
	}

	set(obj: CompanyData | undefined): void {
		this.data = obj;
		this.change();
	}

	setUserList(userList: CompanyData['userList']): void {
		if (!this.data) return;
		this.data.userList = userList;
		this.change();
	}

	addUser(user: IUser): void {
		if (!this.data) return;
		if (!this.data.userList.find(u => u.id === user.id)) this.data.userList.push(user);
		this.change();
	}

	addLambdaUser(user: ILambdaUser): void {
		if (!this.data) return;
		if (!this.data.lambdaList.find(u => u.id === user.id)) this.data.lambdaList.push(user);
		this.change();
	}

	updateUser(user: IUser): void{
		if (!this.data) return;
		this.data.userList = this.data.userList.map((u) => u.id === user.id ? user : u);
		this.change();
	}

	deleteUser(id: string): void {
		if (!this.data) return;
		this.data.userList = this.data.userList.filter((u) => u.id !== id);
		this.change();
	}

	update(): void {
		return;
	}

	getUserCompaniesToManage(id: string): ICompanyManage[] {
		if (!this.data) return [];
		const user = this.data.userList.find((u) => u.id === id);
		if (!user) return [];
		return user.companiesToManage;
	}

	private storeError(error: Error): void {
		this.app.adapter.storeCompanyDTO?.({ data: this, error, loading: false });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryCompanyUsers(): Promise<void>  {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.fetchCompanyUsers).catch(err => {
			this._isQuerying = false;
			this.storeError(err);
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.myCompany);
	}

	async queryCompaniesManagement(): Promise<ICompanyManage[]> {
		const data = await this.callApi(this.app.adapter.queryCompaniesManagement);
		return data.companiesToManage;
	}

	async querySsoSettings(input: IQuerySsoSettingsInput): Promise<IQuerySsoSettingsOutput['ssoSettings']> {
		const data = await this.callApi(this.app.adapter.querySsoSettings, input);
		return data.ssoSettings;
	}

	/**
	 * Mutation to add/remove user from company to manage
	 * @param input = { userId, companyId, manage } -> manage for add/remove user from company to manage
	 */
	async mutateCompanyToManage(input: IMutateCompanyToManageInput): Promise<void> {
		const data = await this.callApi(this.app.adapter.mutateCompanyToManage, input);
		this.setUserList(data.mutateCompanyToManage.userList);
	}

	async mutateCompanyManage(input: ICompanyManage): Promise<ICompanyManage[]> {
		const data = await this.callApi(this.app.adapter.mutateCompanyManagement, input);
		if (this.app.entities.user.data) {
			const updatedCompany = data.mutateCompanyManage.companies.find((company) => company.id === this.app.entities.user.data?.myCompany?.id);
			if (!updatedCompany) return data.mutateCompanyManage.companies;
			this.app.entities.user.data.myCompany = {
				...this.app.entities.user.data.myCompany,
				...updatedCompany
			};
		}
		return data.mutateCompanyManage.companies;
	}
}
