import { gql } from '@apollo/client';

export const MUTATE_LAUNCH_COMPUTE = gql`
    mutation LaunchCompute(
        $datasetsIds: [String!]!
        $name: String
        $resultList: String
        $resultTypesIdentifiers: [Int]
        $computeDatasetsIndividually: Boolean,
        $resetImpacts: Boolean
    ) {
        mutateLaunchCompute(
            datasetsIds: $datasetsIds,
            name: $name,
            resultList: $resultList,
            resultTypesIdentifiers: $resultTypesIdentifiers,
            computeDatasetsIndividually: $computeDatasetsIndividually,
            resetImpacts: $resetImpacts
        ) {
            status,
            resultLauncher {
                id,
                name,
                computeLogs,
                timestamp
            }
            computedIndicators
        }
    }
`;
