import { gql } from '@apollo/client';

export const MUTATE_RESULTS_COMPARISON = gql`
    mutation MutateResultsComparison(
        $resultsIds: [String!]!
        $sampleId: String!
    ){
        mutateResultsComparison(
            resultsIds: $resultsIds
            sampleId: $sampleId
        ){
            results {
                id,
                type,
                relatedToSample,
                lastUpdated,
                data,
                relativeData,
                archived,
                sample {
                    id,
                    name
                }
            }
        }
    }
`;
