import React, { useState } from 'react';
import './MultiChoiceSelector.css';

interface MultiChoiceSelectorOptions {
	id: string,
	value: string,
	label: string,
	tooltip: string
	disabled?: boolean,
}

/**
 * return a Dropdown component with multiple selectable options
 *
 * @param {MultiChoiceSelectorOptions} options of string (value, label and tooltip for each option)
 * @param {string} head which define the head text of the selector
 * @param {(values: string[]) => void} setValues
 * @constructor
 *
 * @author Tanguy Pauvret
 */
const MultiChoiceSelector = ({
	options,
	head,
	setValues
}: {
	options: MultiChoiceSelectorOptions[],
	head: string,
	setValues: (values: string[]) => void
}): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

	const openSelector = () => {
		setOpen(!open);
	};

	/**
	 *  Check if the selected options array selectedOptions includes the current option value passed to the function.
	 *  If it does include the value : current option is already selected, we want to remove it from the selected options.
	 *	If it does not include the value : current option is not selected, we want to add it to the selected options.
	 *
	 * @param value
	 */
	const handleCheck = (value: string) => {

		// .includes(value) return a boolean
		const newSelectedOptions = selectedOptions.includes(value)
			? selectedOptions.filter((option) => option !== value)
			: [...selectedOptions, value];

		setSelectedOptions(newSelectedOptions);
		setValues(newSelectedOptions);
	};

	/**
	 * Important : This component reused the choice_selector_button class from the MultiSelector component
	 * This means that the style of the button is the same as the one from the MultiSelector component in the MultiSelector.css file
	 */

	return (
		<div className={'selector_menu'}>
			<button className={'choice_selector_button multi_select_button'} onClick={openSelector}>
				{`${selectedOptions.length > 0 ? selectedOptions.map(select => options.find(opt => opt.id == select)?.value).join(' ') : head}`}
			</button>
			{open && (
				<div className={`options_container ${open ? 'open' : ''}`}>
					{options.map((option) => (
						<div className={'option_item'} key={option.value} title={option.tooltip}>
							<input
								type="checkbox"
								id={option.id}
								value={option.id}
								checked={selectedOptions.includes(option.id)}
								onChange={() => handleCheck(option.id)}
								aria-label={option.label}
								disabled={option.disabled}
							/>
							<label className={`option_label ${option.disabled ? 'option_label_disabled' : ''}`}
								htmlFor={option.value}>{option.label}</label>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default MultiChoiceSelector;