import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateSamplesManagementInput, IMutateSamplesManagementOutput } from '../../domain/interfaces/IMutateSamplesManagement';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQuerySamplesOutput } from '../../domain/interfaces/IQuerySamples';
import { IQuerySampleListInput, IQuerySampleListOutput } from '../../domain/interfaces/IQuerySampleList';

export class ApolloClientMutateSamplesManagement extends ApolloClientProvider<IMutateSamplesManagementOutput, IMutateSamplesManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSamplesManagementInput): Promise<IMutateSamplesManagementOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQuerySamples extends ApolloClientProvider<IQuerySamplesOutput> {}
export class ApolloClientQuerySampleList extends ApolloClientProvider<IQuerySampleListOutput, IQuerySampleListInput> {}