import { gql } from '@apollo/client';

export const MUTATE_ADD_EQUIPMENT_EXPLORE = gql`
    mutation MutateAddEquipmentExplore(
		$datasetId: String!,
		$blockId: String!,
		$equipments: [AddEquipmentTypeInput]!,
		$toStudy: Boolean!,
    ){
    	mutateAddEquipment(
    		datasetId: $datasetId,
    		blockId: $blockId,
			equipments: $equipments,
			toStudy: $toStudy
		){
			status
			data {
				id,
				name,
				shortName,
				equipments {
					id,
					name,
					help,
					unit,
					unitShortName,
					priority,
					specification,
					quantity,
					quality,
					unknown
					lifetime,
					internalLifetime,
					reusePart,
					reuseLifetime,
					customElectricityConsumption,
					comment,
					assigned,
					assignedCount,
					forbidden,
					equipmentTypeId,
					flag,
					category,
					categoryIdentifier,
					cmdb,
					defaultLifetime
				},
            	cmdb {
            		id,
            		name,
            		category,
            		comment,
            		equipmentsInfo {
            			category,
            			quantity,
            			totalLifetime,
            			numberOfType
					}
				},
				metadata {
					equipmentRate
				},
				priority
			}				
    	}
    }
`;
