import Modal from '../Modal';
import React, { useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { State } from '../../../store';
import { useSelector } from 'react-redux';
import MultiChoiceSelector from '../../selector/MultiChoiceSelector/MultiChoiceSelector';
import './ExportModal.css';
import ButtonLoading from '../../button/ButtonLoading/ButtonLoading';
import ErrorImage from '../../error/ErrorImage/ErrorImage';
import { Alert } from '../../Alerts';
import { useResults } from '../../hooks/useResults';
import { useStudies } from '../../hooks/useStudies';
import { useDatasets } from '../../hooks/useDatasets';
import { useUser } from '../../hooks/useUser';
import { IResultLauncher } from '../../../domain/data/entries/IResultLauncher';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';

/**
 * Return Modal for export results
 *
 * @param onClose {() => void}
 * @param launcher {IResultLauncher}
 * @param definitiveResultExist {boolean}
 * @param downloadGraphs {() => void}
 * @param sampleId {string}
 *
 * @author Tanguy Pauvret
 */
const ExportModal = ({ onClose, launcher, definitiveResultExist, downloadGraphs, sampleId }:{
	onClose: () => void,
	launcher: IResultLauncher,
	definitiveResultExist: boolean,
	downloadGraphs: () => void,
	sampleId?: string
}): JSX.Element => {
	// State
	const [valuesResults, setValuesResults] = useState<string[]>([]);
	const [valuesLCA, setValuesLCA] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorExport, setErrorExport] = useState<boolean>(false);
	const [warnForDefinitiveState, setWarnForDefinitiveState] = useState<boolean>(!definitiveResultExist);

	/**
	 * Array of buttons to display in the 1st page of the modal
	 *
	 * @param title: text to display on the button
	 * @param type: this type will be reused on 2nd page to display the selected options
	 * @param isClicked: boolean to know if the button is clicked or not
	 * @param options: array of options to display on the 2nd page
	 */
	const buttons = [
		{
			title: translate('export.results'),
			type: translate('export.results'),
			isClicked: false,
			function: setValuesResults,
			options: [
				{
					id: 'exportResults',
					value: translate('export.results.rawResults') as string,
					label: translate('export.results.rawResults') as string,
					tooltip: translate('export.rawResultsTooltip') as string
				},
				{
					id: 'exportGraphs',
					value: translate('export.results.graphicalResults') as string,
					label: translate('export.results.graphicalResults') as string,
					tooltip: translate('export.graphicalResultsTooltip') as string
				},
				{ // Not working yet
					id: 'exportResultAsLatex',
					value: translate('export.results.executiveSummary') as string,
					label: translate('export.results.executiveSummary') as string,
					tooltip: translate('export.executiveSummaryTooltip') as string,
					disabled: true
				},
				{
					id: 'exportImpacts',
					value: translate('export.results.exportImpacts') as string,
					label: translate('export.results.exportImpacts') as string,
					tooltip: translate('export.exportImpactsTooltip') as string
				},
				{
					id: 'exportEnvironmentalAssessment',
					value: translate('export.results.exportEnvironmentalAssessment') as string,
					label: translate('export.results.exportEnvironmentalAssessment') as string,
					tooltip: translate('export.exportEnvironmentalAssessmentTooltip') as string
				}
			]
		},
		{
			title: translate('export.dataCollected'),
			type: translate('export.dataCollected'),
			isClicked: false,
			function: setValuesLCA,
			options: [
				{
					id: 'exportDatasetsLcaEquipment',
					value: translate('export.dataCollected.inventory') as string,
					label: translate('export.dataCollected.inventory') as string,
					tooltip: translate('export.inventoryTooltip') as string
				},
				{
					id: 'exportDatasetsLcaMaturity',
					value: translate('export.dataCollected.maturity') as string,
					label: translate('export.dataCollected.maturity') as string,
					tooltip: translate('export.maturityTooltip') as string
				},
				{
					id: 'exportDatasetsLcaSettings',
					value: translate('export.dataCollected.settings') as string,
					label: translate('export.dataCollected.settings') as string,
					tooltip: translate('export.settingsTooltip') as string
				},
			]
		},
	];

	// Variable to catch datas from study and dataset
	const { entity: userEntity } = useUser();
	const { entity } = useResults();
	const studyId = useSelector((state: State) => state.studyID);
	const datasetId = useSelector((state: State) => state.datasetID);
	const { studies } = useStudies();
	const study = studies?.find(s => s.id === studyId);
	const { datasets } = useDatasets(study?.datasetsId ?? []);
	const dataset = datasets?.find(d => d.id === datasetId);
	const { entity: rlEntity } = useResultLaunchers(study?.datasetsId, sampleId);

	const [exportLanguage, setExportLanguage] = useState<string>(userEntity?.getLanguage() ?? 'en');
	
	// Array of languages for export the default is the current language
	const languageLst = [userEntity?.getLanguage(), userEntity?.getLanguage() === 'en' ? 'fr' : 'en'];

	const exportValues = [...valuesResults, ...valuesLCA];
	const downloadExport = () => {
		setLoading(true);
		if (exportValues.includes('exportGraphs')){
			downloadGraphs();
			// remove exportGraphs from the array because at the moment we don't want to download the graphs from backend
			const index = exportValues.indexOf('exportGraphs');
			if (index > -1) {
				exportValues.splice(index, 1);
			}
		}
		if (exportValues.length === 0) {
			onClose();
			return;
		}
		const exportRequirements = {
			launcherId: launcher.id,
			exportImpacts: exportValues.includes('exportImpacts'),
			exportResults: exportValues.includes('exportResults'),
			exportGraphs: exportValues.includes('exportGraphs'),
			exportEnvironmentalAssessment: exportValues.includes('exportEnvironmentalAssessment'),
			exportResultAsLatex: exportValues.includes('exportResultAsLatex'),
			exportDatasetsLcaEquipment: exportValues.includes('exportDatasetsLcaEquipment'),
			exportDatasetsLcaMaturity: exportValues.includes('exportDatasetsLcaMaturity'),
			exportDatasetsLcaSettings: exportValues.includes('exportDatasetsLcaSettings'),
			lang: exportLanguage,
		};
		entity?.mutateExport(exportRequirements)
			.then(blob => {
				const tempLink = document.createElement('a');
				tempLink.style.display = 'none';
				tempLink.href = URL.createObjectURL(blob);
				tempLink.setAttribute('download', 'export.zip');

				if (typeof tempLink.download === 'undefined') {
					tempLink.setAttribute('target', '_blank');
				}

				document.body.appendChild(tempLink);
				tempLink.click();

				setTimeout(function () {
					document.body.removeChild(tempLink);
					onClose();
				}, 200);
			}).catch(() => {
				setErrorExport(true);
			}).finally(() => {
				setLoading(false);
			});
	};

	const handleSetDefinitiveState = () => {
		rlEntity?.mutateManageResultLauncher({
			id: launcher.id,
			definitive: true
		}).then(() => {
			setWarnForDefinitiveState(false);
		});
	};

	const renderWarnDefinitiveState = () => {
		return (<>
			<div className={'modal_body'}>
				<Alert variant={'danger'}>
					<i className={'m2 fas fa-exclamation-triangle'}/>
					{translate('export.results.warnDefinitiveResult')}
				</Alert>
			</div>
			<div className={'modal_footer'}>
				<button type={'button'} className={'button_danger'} onClick={() => setWarnForDefinitiveState(false)}>
					{translate('cancel')}
				</button>
				<button className={'button_primary'} onClick={handleSetDefinitiveState}>
					{translate('results.setToDefinitive')}
				</button>
			</div>
		</>);
	};

	return (<Modal onClose={onClose}>
		<>
			{errorExport && <ErrorImage errorText={translate('export.errorExport') as string} img='bug_fixing_man' modal={true} onClose={() => setErrorExport(false)}/>}
			{!errorExport && <div className={'modal_content'}>
				<div className={'modal_header'}>
					<h1>{translate('result.ExportModalTitle')}</h1>
					<h3>{study && study.name} - {dataset && dataset.name}</h3>
				</div>

				{warnForDefinitiveState && renderWarnDefinitiveState()}

				{!warnForDefinitiveState && <>
					{(exportValues.includes('exportDatasetsLcaEquipment') ||
						exportValues.includes('exportDatasetsLcaMaturity') ||
						exportValues.includes('exportDatasetsLcaSettings')) &&
						<Alert variant={'danger'}>
							<i className={'m2 fas fa-exclamation-triangle'}/>
							{translate('export.dataCollected.warning')}
						</Alert>
					}

					<div className={'modal_body'}>
						{buttons.map((button, idx) => <div key={idx}>
							<h4>{button.title}</h4>
							<MultiChoiceSelector options={button.options} head={''} setValues={button.function}/>
						</div>)}
						<select className={'select_language'} onChange={e => setExportLanguage(e.target.value)}>
							<option value={languageLst[0]}>{translate(`result.${languageLst[0]}`)}</option>
							<option value={languageLst[1]}>{translate(`result.${languageLst[1]}`)}</option>
						</select>
					</div>

					<div className={'modal_footer'}>
						<button className={'button_danger'} onClick={onClose}>{translate('cancel')}</button>
						<ButtonLoading
							title={'download'}
							classname={'button_primary'}
							loading={loading}
							onClick={downloadExport}
						/>
					</div>
				</>}
			</div>}
		</>
	</Modal>);
};

export default ExportModal;