import { gql } from '@apollo/client';

export const MUTATE_MANAGE_CMDB = gql`
    mutation MutateManageCmdb(
      $cmdbId: String!
	  $delete: Boolean!
	  $newName: String
	  $categoryIdentifier: String
	  $values: EquipmentInput
	  $equipmentIds: [String]
	  $comment: String
    ) {
    	mutateManageCmdb (
      		cmdbId: $cmdbId
			delete: $delete
			newName: $newName
			categoryIdentifier: $categoryIdentifier
			values: $values
			comment: $comment
		) {
			status,
			errors,
        	data {        
            	id,
            	name,
            	shortName,
            	equipments(equipmentIds: $equipmentIds) {
            	    id,
            	    name,
            	    help,
            	    unit,
            	    priority,
            	    specification,
            	    quantity,
            	    quality,
            	    unknown
            	    lifetime,
            	    internalLifetime,
            	    reusePart,
            	    reuseLifetime,
            	    comment,
            	    assigned,
            	    assignedCount,
            	    forbidden,
            	    equipmentTypeId,
            	    flag,
            	    category,
            	    cmdb
            	},
            	cmdb {
            		id,
            		name,
            		category,
            		comment,
            		equipmentsInfo {
						validated,
            			category,
						categoryIdentifier,
            			quantity
            			totalLifetime,
						lifetime,
						allocationFactor,
					}
				},
            	metadata {
            	    equipmentRate
            	},
            	priority
            }
		}
    }
`;
