import ChoiceSelector from '../../viewComponents/head/choiceSelector/choiceSelector';
import { translate } from '../../infrastructure/translations/translate';
import { IResultLauncher } from '../../domain/data/entries/IResultLauncher';
import React, { useEffect, useState } from 'react';
import { useResultLaunchers } from '../../viewComponents/hooks/useResultLaunchers';
import { useModal } from '../../viewComponents/modal/useModal';
import {
	createStudyColorSettingsModal
} from '../../viewComponents/modal/StudyColorSettingsModal/StudyColorSettingsModal';
import { useFilterIndicatorModal } from '../../viewComponents/modal/FilterIndicatorModal/FilterIndicatorModal';
import { useSampleResultComparison } from '../../viewComponents/modal/ResultComparisonModal/ResultComparisonModal';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { useBlocks } from '../../viewComponents/hooks/useBlocks';
import { IQueryColorsOutput } from '../../domain/interfaces/IQueryColors';
import { useStudies } from '../../viewComponents/hooks/useStudies';
import { useUser } from '../../viewComponents/hooks/useUser';
import { CORP_COLORS } from '../../config';
import { useResults } from '../../viewComponents/hooks/useResults';
import { useSamples } from '../../viewComponents/hooks/useSamples';
import { closeGlobalModal } from '../../viewComponents/modal/GlobalModal';
import { useTooltip } from '../../viewComponents/tip/useTooltip';
import { adapter } from '../../infrastructure';

const EditResultLauncherNameInput = ({ editLauncherName, launcherNameEdited, setLauncherNameEdited, setOpenNameEdition } : {
	editLauncherName: () => void
	setOpenNameEdition: (value: boolean) => void,
	launcherNameEdited: string | null,
	setLauncherNameEdited: (value: string | null) => void,
}) => {

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			editLauncherName();
		}
		else if (event.key === 'Escape') {
			setOpenNameEdition(false);
			setLauncherNameEdited(null);
		}
	};

	return (
		<input
			className="edit_result_launcher_name_input"
			type={'text'}
			value={launcherNameEdited ?? ''}
			onChange={(e) => (setLauncherNameEdited(e.target.value))}
			onKeyDown={handleKeyDown}
		/>
	);
};

const SetResultDefinitiveButton = ({ id }: { id: string }): JSX.Element => {
	const { entity: rlEntity, resultLaunchers } = useResultLaunchers();
	const currentRl = resultLaunchers.find(rl => rl.id === id);
	const backgroundColor = currentRl?.definitive ? CORP_COLORS.green : CORP_COLORS.red;
	const tooltip = currentRl?.definitive ? 'results.setToNotDefinitive' : 'results.setToDefinitive';

	if (!currentRl) return <></>;

	// This will warn the user before allowing him to change the definitive status
	const setDefinitive = () => {
		adapter.storeUserNotification({
			title: currentRl?.definitive ? 'results.notification.setToNotDefinitive' : 'results.notification.setDefinitive',
			redirectPath: () => rlEntity?.mutateManageResultLauncher({
				id: id,
				definitive: !currentRl?.definitive
			}),
			footer: true
		});
	};

	const icon = currentRl.definitive ? 'fa-lock-open' : 'fa-lock';

	return (
		<button
			title={translate(tooltip) as string}
			className={'button_primary m1'}
			style={{ backgroundColor }}
			onClick={setDefinitive}>
			<i className={`fa-solid ${icon}`}/>
		</button>);
};

/**
 * Return button to set Result as Private or Public
 * Button show red if it's to set private and green if it's to set public
 * @param {string} id
 * @param {boolean} isPrivate
 * @return JSX.Element
 *
 * @author Maximilien Valenzano
 */
const SetResultPrivateOrPublicButton = ({ id }: { id: string }): JSX.Element => {
	const { entity: rlEntity, resultLaunchers } = useResultLaunchers();
	const currentRl = resultLaunchers.find(rl => rl.id === id);
	const backgroundColor = currentRl?.private ? CORP_COLORS.green : CORP_COLORS.red;
	const text = currentRl?.private ? 'results.setToPublic' : 'results.setToPrivate';

	const setPrivateOrPublic = () => {
		rlEntity?.mutateManageResultLauncher({
			id: id,
			private: !currentRl?.private
		});
	};

	return (
		<button
			title={translate(text) as string}
			className={'button_primary m1'}
			style={{ backgroundColor }}
			onClick={setPrivateOrPublic}>
			<i className="fa-solid fa-users"/>
		</button>);
};

const ResultHeader = ({ datasetsIds, launcher, handleLauncherChange, loadingResults, setOpenModalResults, setOpenModalExport, setQueryError, archived, sampleId }: {
	datasetsIds: string[],
	launcher: IResultLauncher | undefined,
	handleLauncherChange: (value: string) => void,
	loadingResults: boolean,
	setOpenModalResults: (value: boolean) => void,
	setOpenModalExport: (value: boolean) => void,
	setQueryError: (value: boolean) => void,
	archived?: boolean,
	sampleId?: string,
}) => {
	const { entity: studiesEntity, studies } = useStudies();

	const studyId = useSelector((state: State) => state.studyID);
	const study = studies?.find(s => s.id === studyId);

	const { entity: blocksEntity } = useBlocks();
	const { entity: resultsEntity, results } = useResults();
	const { entity: rlEntity, resultLaunchers: data } = useResultLaunchers(datasetsIds, sampleId);
	const { samples: sampleList } = useSamples(study ? [study.functionalUnit.name] : undefined);
	const { data: logged } = useUser();

	const accessExport = logged?.permissions.includes('login.can_export_results_as_excel');
	const canArchiveResults = logged?.permissions.includes('login.can_archive_results');
	const canSetResultsPrivate = logged?.permissions.includes('login.can_set_results_private');
	const canSetResultsDefinitive = logged?.permissions.includes('login.can_set_results_definitive');
	const canFilterIndicator = logged?.permissions.includes('login.can_filter_indicators');


	const [colors, setColors] = useState<IQueryColorsOutput | null>(null);
	const [openNameEdition, setOpenNameEdition] = useState(false);

	const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
	const [launcherNameEdited, setLauncherNameEdited] = useState<string | null>(null);
	const [openModalColor, setOpenModalColor] = useState(false);
	const [openFilterIndicatorModal, setOpenFilterIndicatorModal] = useState(false);
	const [queryResultsCompared, setQueryResultsCompared] = useState(false);
	const [tooltip, setTooltip] = useState<string | null>(null);

	const handleSampleChange = (resultsIds: string[], sample: string): void => {
		if (resultsIds.length === 0) return;
		setQueryResultsCompared(true);
		resultsEntity?.mutateResultsComparison({ resultsIds: resultsIds, sampleId: sample }).catch(() => {
			setQueryError(true);
		}).finally(() => {
			setQueryResultsCompared(false);
			setComparisonModalOpen(false);
			closeGlobalModal();
		});
	};

	useModal(openModalColor, createStudyColorSettingsModal(studyId ?? '0', openModalColor, setOpenModalColor, colors));
	// calling filter indicator modal only on dataset result view (avoid on All and Sample)
	useModal(openFilterIndicatorModal && datasetsIds.length === 1 && !sampleId, useFilterIndicatorModal(openFilterIndicatorModal, setOpenFilterIndicatorModal));
	useModal(comparisonModalOpen, useSampleResultComparison(
		comparisonModalOpen,
		results.map(result => result.id) ?? [],
		setComparisonModalOpen,
		sampleList,
		handleSampleChange,
		queryResultsCompared,
		setTooltip
	));
	useTooltip(tooltip !== null, tooltip);

	useEffect(() => {
		// Fetch colors if all the needed objects are loaded in the stores
		if (studyId && blocksEntity && !colors) {
			// Don't fetch colors if we are on the sample page
			if (!location.pathname.includes('sample')){
				studiesEntity?.queryColors({ studyId: studyId }).then((receivedColors) => {
					if (!receivedColors) return;
					setColors(receivedColors);
				});
			}
			else {
				// If we are on the sample page, clear the colors
				blocksEntity.clearColors();
			}
		}
	}, []);

	if (!rlEntity) return <></>;

	const resultsLaunchers = [...data].reverse();

	if (!launcher) return <></>;

	const definitiveResultExist = resultsLaunchers.some(launcher => launcher.definitive);
	const isDefinitiveResult = launcher.definitive;
	const ready: boolean = resultsLaunchers.length > 0;

	const craftLauncherList = (launchers: IResultLauncher[]): { value: string, name: string, component: JSX.Element }[] => {
		return launchers.map((launcher: IResultLauncher) => {
			const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}(?:\+\d{2}:\d{2})? by .+/;
			let dateAndName = launcher.name;

			if (regex.test(launcher.name)) {
				// split the string to get the date and the name
				const name = launcher.name.split(' ').slice(2).join(' ');
				const dateAndTime = launcher.name.split(' ').slice(0, 2).join(' ');
				// create a date object to format the date
				const dateObject = new Date(dateAndTime);
				// format the date to local time and add the name
				dateAndName = dateObject.toLocaleString() + ' ' + name;
			}
			return {
				value: launcher.id,
				name: dateAndName,
				component:
					<>
						{launcher.definitive ?
							<i className="fa-solid fa-lock"/>
							: launcher.archived
								? <i className="fa-solid fa-box-archive"/>
								: <i className="fa-solid fa-folder-open"/>
						}
						{dateAndName}
					</>
			};
		});
	};

	const editLauncherName = () => {
		if (!launcherNameEdited) return;
		rlEntity?.mutateManageResultLauncher({ id: launcher.id, name: launcherNameEdited });
		setOpenNameEdition(false);
		setLauncherNameEdited(null);
	};

	return (
		<div className="result_head_bar">
			{openNameEdition
				? <EditResultLauncherNameInput
					editLauncherName={editLauncherName}
					setOpenNameEdition={setOpenNameEdition}
					launcherNameEdited={launcherNameEdited}
					setLauncherNameEdited={setLauncherNameEdited}
				/>
				: <ChoiceSelector
					selectorName={'launcherSelector'}
					name={translate('results.launcherSelector') as string}
					selected={launcher.id}
					list={craftLauncherList(resultsLaunchers)}
					handle={handleLauncherChange}
					disabled={loadingResults}
				/>
			}

			{!isDefinitiveResult && <button
				className={`edit_result_launcher_name_${openNameEdition ? 'open' : 'close'}`}
				onClick={() => {
					if (openNameEdition) editLauncherName();
					else setOpenNameEdition(true);
				}}>
				<i className={openNameEdition ? 'fa-solid fa-check' : 'fa-solid fa-pen'}/>
			</button>}

			{openNameEdition && <button onClick={() => setOpenNameEdition(false)}>
				<i className="fa-solid fa-xmark"/>
			</button>}

			<div className='result_head_bar_buttons'>
				{canSetResultsDefinitive && (!definitiveResultExist || isDefinitiveResult) && <SetResultDefinitiveButton id={launcher.id}/>}

				<button
					className={'button_primary'}
					type={'button'}
					onClick={() => setComparisonModalOpen(true)}
					title={(translate('results.compareResults') as string)}
				>
					<i className="fa-solid fa-scale-balanced"/>
				</button>

				{accessExport &&
					<button
						className={ready ? 'button_primary' : '.button_none'}
						type={'button'}
						onClick={() => setOpenModalExport(true)}
						disabled={(!ready)}
						title={(translate('results.exportResults')) as string}
					>
						<i className="fa-solid fa-file-export"/>
					</button>}

				{canSetResultsPrivate && <SetResultPrivateOrPublicButton id={launcher.id}/>}

				{!location.pathname.includes('sample') && datasetsIds.length === 1 &&
					<button
						className={'button_primary'}
						type={'button'}
						onClick={() => setOpenModalColor(true)}
						title={(translate('results.setColors')) as string}
					>
						<i className='fa-regular fa-palette'/>
					</button>
				}

				{canFilterIndicator && datasetsIds.length === 1 && !sampleId && <button
					className={'button_primary'}
					type={'button'}
					onClick={() => setOpenFilterIndicatorModal(true)}
					title={(translate('filterIndicatorModal.title')) as string}
				>
					<i className='fa-regular fa-filter'/>
				</button>}

				{/*Can't archive a definitive result*/}
				{!archived && canArchiveResults && !launcher.archived && !isDefinitiveResult && <button
					title={translate('validation.archiveResults') as string}
					type={'button'}
					style={{ backgroundColor: CORP_COLORS.red }}
					onClick={() => setOpenModalResults(true)}
					className={'button_primary m1'}
				>
					<i className={'fa-solid fa-box-archive'}/>
				</button>}
			</div>
		</div>
	);
};

export default ResultHeader;