import React, { useId } from 'react';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { translate } from '../../../../infrastructure/translations/translate';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { DashboardPinButton } from '../../DashboardPin';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { getColors } from '../../../../config';
import { LineChart, LineChartData } from '../../../../viewComponents/graphs/bars/LineChart';
import { ChartProps } from 'react-chartjs-2';
import { DashboardPluginGraphBarLegend, DashboardEvolutionPluginGraphBarRefDiff } from '../../DashboardPluginGraphBarLegend';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import {
	GroupedBarChart,
	GroupedBarChartData
} from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { IBlock } from '../../../../domain/interfaces/IBlock';
import { TooltipItem } from 'chart.js/auto';
import { DashboardSelectReference } from '../../DashboardSelectReference';
import { compareEvolutionValueToRefValue } from '../DashboardEvolution';

type DashboardDomainsEvolutionGraphDataLine = {
	label: string;
	color?: string;
	dashed?: boolean;
	values: {
		scope: string;
		value: number;
	}[];
}

export interface DashboardDomainsEvolutionGraphData {
	indicators: string[];
	lifeCycleSteps: string[];
	footprintType: number;
	indicator: string;
	dataset: string;
	lifeCycleStep: string;
	graph: (DashboardDomainsEvolutionGraphDataLine | null)[];
	reference: string | null;
}

const options: ChartProps<'line' | 'bar'>['options'] = {
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'line' | 'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	}
};

export const DashboardDomainsEvolutionGraph = ({ data, showTable, histogram, unit }: { data: DashboardDomainsEvolutionGraphData, showTable: boolean, histogram?: boolean, unit: string }): JSX.Element => {
	const { graph: graphData, reference } = data;
	const id = useId();
	const years = graphData
		.filter(e => e)
		.map(e => e?.values.map(v => v.scope))
		.flat()
		.filter(unique)
		.sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	const graphLine: (LineChartData | null)[] = years.map((year) => ({
		label: year ?? '',
		values: graphData
			.map(d => {
				const find = d?.values.find(v => v.scope === year);
				return {
					scope: d?.label ?? '',
					value: find?.value ?? null,
					color: d?.color ?? 'black',
					dashed: d?.dashed ?? false
				};
			})
	}));

	graphLine.unshift(null);
	graphLine.push(null);
	if (graphLine.length < 4) {
		graphLine.unshift(null);
		graphLine.push(null);
	}

	const colors = [...getColors((graphData.find(d => d)?.values.length ?? 1), true)];

	const graphBar: (GroupedBarChartData | null)[] = graphData.map(d => d ? ({
		label: d.label,
		values: years
			.filter(y => d.values.find(v => v.scope === y))
			.map((y, i) => ({
				scope: y ?? '',
				value: d.values.find(v => v.scope === y)?.value ?? 0,
				color: d.color === 'black' ? i == d.values.length - 1 ? 'black' : 'gray' : undefined,
			}))
	}) : null);

	return <div className="dashboard_graph">
		{!showTable && <>
			<div className="dashboard_chart_container">
				{unit && <div className="chart_indicator_unit">
					{unit}
				</div>}
				{!histogram && <LineChart
					data={graphLine}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id)]}
				/>}
				{histogram && <GroupedBarChart
					data={graphBar}
					datasetRef={reference}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id), DashboardEvolutionPluginGraphBarRefDiff()]}
					thin={15}
					percentage={1}
					colors={colors}
					categoryPercentage={.5}
					borderRadius={3}
				/>}
			</div>
			<div id={id} className="dashboard_graph_legend"/>
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					{years.map((year, i) => (
						<th key={i}>{year}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{graphData
					.filter(d => d)
					.map((d, i) => (<tr key={i}>
						<td>{d?.label}</td>
						{years.map((year, j) => {
							const find = d?.values.find(d => d.scope === year);
							const refValue = d?.values.find(v => v.scope === reference)?.value;
							return (
								<td style={{ fontWeight: reference === year ? 'bold' : 'normal' }} key={j}>
									<span className={'number value'}>
										{resultValue(find?.value)}
										{reference && year !== reference &&
                                            <span className="number ref">{compareEvolutionValueToRefValue(refValue, find?.value)}</span>}
									</span>
								</td>
							);
						})}
					</tr>))}
			</tbody>
		</table>}
	</div>;
};

const DashboardDomainsEvolution = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);

	const [selectFootprintType, setSelectFootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [reference, setReference] = React.useState<string | null>(null);

	const DifferentStudyNames = data.datasets.map(d => d.study).filter(unique).length > 1;
	const DifferentCompanyNames = data.datasets.map(d => d.company).filter(unique).length > 1;
	const getName = (d: {
		name?: string,
		study?: string,
		company?: string
	}): string => {
		let name = '';
		if (DifferentCompanyNames) name = ` - ${d.company}`;
		if (DifferentStudyNames) name = ` - ${d.study}` + name;
		return d.name + name;
	};
	const datasetNames = data.datasets.map(d => getName(d)).filter(unique);
	const [selectDatasetName, setSelectDatasetName] = React.useState<string>(datasetNames[0]);

	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [histogram, setShowHistogram] = React.useState<boolean>(false);
	const isLineMode = !histogram && !showTable;

	const colors = getColors(domains.length, true);
	const domainColors = domains.map((domain, i) => ({
		id: domain.id,
		color: colors[i]
	}));

	const selectedDatasets: DashboardComparisonData['datasets'] = data.datasets.filter(d => getName(d) === selectDatasetName);
	const years = selectedDatasets.map(d => d.year).filter(unique).sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	const parseDatasetValue = (domain: IBlock, dataset: DashboardComparisonData['datasets'][0]) => {
		const value = dataset.equipments.reduce((accEquipment, equipment) => {
			if (equipment.domain !== domain.id) return accEquipment;
			return (accEquipment ?? 0) + equipment.impacts.reduce((accImpact, impact) => {
				return accImpact + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
			}, 0);
		}, 0);
		return valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType, dataset, value));
	};

	const graphData: (DashboardDomainsEvolutionGraphDataLine | null)[] = domains.map(domain => ({
		label: domain.name,
		color: domainColors.find(d => d.id === domain.id)?.color,
		values: years
			.filter(y => selectedDatasets.find(d => d.year === y))
			.map(y => {
				const dataset = selectedDatasets.find(d => d.year === y);
				return {
					scope: y,
					value: dataset ? parseDatasetValue(domain, dataset) : 0
				};
			})
	}));

	const saveData = {
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		indicator: selectIndicator,
		dataset: selectDatasetName,
		lifeCycleStep: selectLifeCycleStep,
		graph: graphData,
		reference: reference
	};

	return <div className="dashboard_card dashboard_card_color_yellow">
		<h3>{translate('dashboard.title.domainsEvolution')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={e => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectDatasetName}
				onChange={(e) => setSelectDatasetName(e.target.value)}
			>
				{datasetNames.map((d, i) => (
					<option key={i} value={d}>{d}</option>
				))}
			</select>
			<select
				value={selectIndicator}
				onChange={e => setSelectIndicator(e.target.value)}
			>
				<option
					value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName})
						- {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
			{!isLineMode && <DashboardSelectReference data={data} reference={reference} setReference={setReference} isEvolution={true}/>}
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowHistogram(!histogram)}
				>
					<div className="dashboard_icon">
						{histogram && <i className="fa-solid fa-chart-column"/>}
						{!histogram && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{histogram && translate('dashboard.action.histogram.hide')}
						{!histogram && translate('dashboard.action.histogram.show')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column"/>}
						{!showTable && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardDomainsEvolution'}/>
			</div>
			<DashboardDomainsEvolutionGraph
				data={saveData}
				showTable={showTable}
				histogram={histogram}
				unit={indicators.find(i => i.id === selectIndicator)?.unit ?? translate('results.valuePBCI') as string}
			/>
		</div>
	</div>;
};

export default DashboardDomainsEvolution;