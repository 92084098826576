import { gql } from '@apollo/client';

export const MUTATE_MANAGE_RESULT_LAUNCHER = gql`
    mutation MutateManageResultLauncher(
        $id: String!,
        $name: String,
        $private: Boolean,
        $definitive: Boolean,
    ){
        mutateManageResultLauncher(
        	id: $id,
			name: $name,
			private: $private,
			definitive: $definitive,
        ){
            status,
            resultLauncher {
                id,
                name,
                computeLogs,
                timestamp,
                private,
                definitive,
                datasets {
                	id
                	permissions {
                		collectOpen,
		                validationOpen
            		}
                }
            }
        }
    }
`;
