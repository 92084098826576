import React, { useState } from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { BarChart, BarData } from '../../../../viewComponents/graphs/bars/BarChart';
import { translate } from '../../../../infrastructure/translations/translate';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { IIndicator } from '../../../../domain/interfaces/IIndicator';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import {
	StackedBarChart,
	StackedBarChartData
} from '../../../../viewComponents/graphs/bars/StackedBarChart';
import {
	DashboardPluginGraphBarLegend,
	DashboardPluginGraphBarPercentageOnTop
} from '../../DashboardPluginGraphBarLegend';
import { DashboardPinButton } from '../../DashboardPin';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { dashboardLabelName } from '../../DashboardLabelName';
import DashboardIndicatorsSelector from '../../DashboardIndicatorsSelector';
import { CoreScaleOptions, Scale } from 'chart.js';
import { TooltipItem } from 'chart.js/auto';

const baseOptions: ChartProps<'bar'>['options'] = {
	maintainAspectRatio: false,
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				},
			},
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
			},
		},
	},
};

const barOptions: ChartProps<'bar'>['options'] = {
	...baseOptions,
	scales: {
		...baseOptions.scales,
		y: {
			afterFit: function(scaleInstance: Scale<CoreScaleOptions>) {
				scaleInstance.width = 100; // sets the width to 100px
			}
		}
	},
};

const stackedOptions: ChartProps<'bar'>['options'] = {
	...baseOptions,
	aspectRatio: 3,
};

export interface DashboardIndicatorsSimulationGraphData {
	indicators: string[],
	graph: {indicator: IIndicator, graph: (BarData | null)[]}[],
	datasetRef: string | null
}
export const DashboardIndicatorsSimulationGraph = ({ showTable, data, pins }: { showTable: boolean, data: DashboardIndicatorsSimulationGraphData, pins?: boolean }) => {
	const { graph: graphData, datasetRef } = data;

	const comparison = (e: number, indicator: string): React.ReactElement => {
		if (datasetRef === '') return <></>;
		const refValue = graphData.find((e) => e?.indicator.name === indicator)?.graph.find((e) => e?.label === datasetRef)?.value;
		if (!refValue || !e || !(e > 0 || refValue > 0)) return <></>;
		const diff = e - refValue;
		const percentage = diff / refValue * 100;
		const text = `${diff < 0 ? '' : '+'}${percentage.toFixed(0)}%`;
		return <span style={{ color: diff > 0 ? '#f00' : '#00ad00' }}>{text}</span>;
	};

	return (<>
		{graphData?.map((g) => (
			<div className="dashboard_graph_multiple" key={`indicator_${g.indicator.shortName}`}>
				<div className="dashboard_graph_multiple_head">
					<h4>{g.indicator.shortName}</h4>
					<div>{g.indicator.name}</div>
					<div>{g.indicator.unit}</div>
				</div>
				<div className='dashboard_graph_multiple_graph'>
					{!showTable && <BarChart
						barData={g.graph}
						options={barOptions}
						plugins={[DashboardPluginGraphBarPercentageOnTop()]}
						legend={''}
						thin={20}
						percentage={0.2}
						borderRadius={3}
						height={140}
					/>}
					{showTable &&
						<table className="dashboard_table">
							<thead>
								<tr>
									<th>{translate('dataset')}</th>
									<th>{translate('unit')}</th>
									<th className={'align-right'}>{translate('value')}</th>
								</tr>
							</thead>
							<tbody>
								{g.graph.filter((e) => e !== null).map((e , i) => (
									<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
										<td>{e?.label}</td>
										<td>{g.indicator.unit}</td>
										<td className={'value'}>
											<span className={'number value'}>
												{resultValue(e?.value)}
												{datasetRef && datasetRef !== e?.label && <span className="number ref">
													{comparison(e?.value ?? -1, g.indicator.name)}
												</span>}
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					}
				</div>
				{pins && <div className="dashboard_graph_tips">
					<DashboardPinButton data={{ ...data, graph: [g], indicator: g.indicator.id }} type={'DashboardIndicatorsComparison'} text={false} />
				</div>}
			</div>)
		)}
	</>);
};
export interface DashboardIndicatorsGroupedSimulationGraphData {
	indicators: string[],
	graph: (StackedBarChartData | null)[],
	datasetRef: string | null
}
export const DashboardIndicatorsGroupedSimulationGraph = ({ showTable, data }: { showTable: boolean, data: DashboardIndicatorsGroupedSimulationGraphData }) => {
	const { graph: graphData, datasetRef } = data;

	const { selectedIndicators: indicators } = useIndicators(data.indicators);

	if (!indicators) return <></>;

	const legendLen = (Math.ceil(
		indicators.reduce(
			(acc, indicator) => acc > indicator.shortName.length ? acc : indicator.shortName.length, 0
		)
	) * 18) * (Math.floor(indicators.length / 10) + 1);
	const id = Math.random().toString(36).slice(2, 9);
	const stackedComparison = (e: number, i: number): React.ReactElement => {
		if (datasetRef === '') return <></>;
		const refValue = graphData.find((e) => e?.label === datasetRef)?.values.find((e) => e?.scope === scopeArray[i])?.value;
		if (!refValue || !(e > 0 || refValue > 0)) return <></>;
		const diff = e - refValue;
		const percentage = diff / refValue * 100;
		const text = `${diff < 0 ? '' : '+'}${percentage.toFixed(0)}%`;
		return <span style={{ color: diff > 0 ? '#f00' : '#00ad00' }}>{text}</span>;
	};

	// Create an array of scope, that will iterate over stackedBarData array, look inside values array and take scope value, then filter unique values
	const scopeArray = graphData.filter((e) => e !== null).map((e) => e?.values.map((e) => e.scope)).flat().filter(unique);

	return (<div className="dashboard_graph" style={{ paddingRight: showTable ? 0 : legendLen }}>
		{!showTable && <>
			<StackedBarChart
				data={graphData}
				options={stackedOptions}
				plugins={[DashboardPluginGraphBarLegend(id), DashboardPluginGraphBarPercentageOnTop()]}
				thin={30}
				percentage={0.5}
				borderRadius={3}
			/>
			<div id={id} className="dashboard_graph_legend" />
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					{scopeArray.map((e, i) => (
						<th key={i} className={'align-right'} style={{ padding: '10px 20px 10px 20px' }}>{e}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{graphData.filter((e) => e !== null).map((e, i) => (
					<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
						<td>{e?.label}</td>
						{e?.values.map((data, idx) => (
							<td key={i} className={'value'} style={{ padding: '20px' }}>
								<span className={'number value'}>
									{resultValue(data.value)}
									{datasetRef && datasetRef !== e?.label && <span className="number ref" style={{ top: '0' }}>
										{stackedComparison(data.value ?? -1, idx)}
									</span>}
								</span>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>}
	</div>);
};

const DashboardIndicatorsSimulation = ({
	data
}: {
	data: DashboardComparisonData
}): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);

	const [selectFootprintType, setSelectFootprintType] = useState<number>(0);
	const [selectDomain, setSelectDomain] = useState<string>('all');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = useState<string>('all');
	const [showTable, setShowTable] = useState<boolean>(false);
	const [isIndicatorGrouped, setIsIndicatorGrouped] = useState<boolean>(false);
	const [selectedIndicators, setSelectedIndicators] = useState<string[]>(indicators?.map(i => i.id) ?? []);

	const datasetRef = data.datasets[0];

	const selectIndicators = (selectedIndicators: string[]) => {
		setSelectedIndicators(selectedIndicators);
		if (selectedIndicators.includes('allGrouped')) {
			setIsIndicatorGrouped(true);
		} else {
			setIsIndicatorGrouped(false);
		}
	};

	if (!indicators) return <></>;

	const parseDataset = (dataset: DashboardComparisonData['datasets'][number], filterIndicator: string) => {
		const value = dataset.equipments.reduce((acc, equipment) => {
			if (selectDomain !== 'all' && equipment.domain !== selectDomain) return acc;
			return acc + equipment.impacts.reduce((acc, impact) => {
				if (filterIndicator !== 'normalized' && impact.indicator !== filterIndicator) return acc;
				return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), filterIndicator, indicators).value;
			}, 0);
		}, 0);
		const indicator = indicators?.find(i => i.id === filterIndicator);
		return {
			label: dashboardLabelName({ id: dataset.id, data }),
			value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType,dataset,value)),
			scope: indicator?.unit ?? translate('results.valuePBCI') as string,
			color: dataset.id == datasetRef.id ? '#474848' : '#e67171'
		};
	};

	const graphData: {
		indicator: IIndicator,
		graph: (BarData | null)[]
	}[] = indicators?.filter(
		indicator => selectedIndicators.includes(indicator.id) || selectedIndicators.includes('all')
	).map(indicator => ({
		indicator,
		graph: data.datasets.map(d => parseDataset(d, indicator.id))
	})) ?? [];
	graphData.forEach(gd => {
		if (gd.graph.length < 4) {
			gd.graph.unshift(null);
			gd.graph.push(null);
		}
	});

	// parseDataset but using all indicators in one single chart
	const parseDatasetAllIndicators = (dataset: DashboardComparisonData['datasets'][number]) => {
		const values = indicators?.map(indicator => {
			const value = dataset.equipments.reduce((acc, equipment) => {
				if (selectDomain !== 'all' && equipment.domain !== selectDomain) return acc;
				return acc + equipment.impacts.reduce((acc, impact) => {
					if (impact.indicator !== indicator.id) return acc;
					return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), 'normalized', indicators).value;
				}, 0);
			}, 0);
			return {
				scope: indicator.shortName,
				value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType,dataset,value)),
			};
		});
		return {
			label: `${dataset.name} - ${dataset.study} - ${dataset.company}`,
			values
		};
	};

	const stackedBarData: (StackedBarChartData | null)[] = data.datasets.map(parseDatasetAllIndicators);
	if (stackedBarData.length < 4) {
		stackedBarData.unshift(null);
		stackedBarData.push(null);
	}
	// Iterate over stackedBarData array, look inside values array and drop indexes that have value === 0
	stackedBarData.forEach((e, i) => {
		if (e === null) return;
		e.values = e.values.filter(e => e.value !== 0);
		if (e.values.length === 0) stackedBarData[i] = null;
	});

	const saveData = {
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		domain: selectDomain,
		lifeCycleStep: selectLifeCycleStep,
		graph: [],
		datasetRef: stackedBarData.filter(d => d != null)[0]?.label ?? ''
	};

	return <div className={'dashboard_card dashboard_card_color_red'}>
		<h3>{translate('dashboard.title.indicatorsSimulation')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={(e) => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<DashboardIndicatorsSelector indicators={indicators} selectedIndicators={selectedIndicators} setSelectedIndicators={selectIndicators} allGrouped={true} />
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.shortName}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={isIndicatorGrouped ? { ...saveData, graph: stackedBarData } : { ...saveData, graph: graphData }} type={isIndicatorGrouped ? 'DashboardIndicatorsGroupedSimulation' : 'DashboardIndicatorsSimulation'} />
			</div>
			{!isIndicatorGrouped && <DashboardIndicatorsSimulationGraph showTable={showTable} data={{ ...saveData, graph: graphData }} pins={true} />}
			{isIndicatorGrouped && <DashboardIndicatorsGroupedSimulationGraph showTable={showTable} data={{ ...saveData, graph: stackedBarData }} />}
		</div>
	</div>;
};

export default DashboardIndicatorsSimulation;