import { Entity } from '../core/Entity';
import { ILifeCycleStep } from '../interfaces/IQueryInventoryExtraContent';

export class LifeCycleStepEntity extends Entity {
	public data: ILifeCycleStep[] | undefined;

	initialization() {
		this.app.adapter.storeDataLifeCycleSteps?.({ loading: false, data: this, error: null });
	}

	get(): ILifeCycleStep[] | undefined {
		if (!this.data) {
			if (this.app.entities.lca.isQuerying) return;
			this.app.entities.lca.queryLifeCycleAssessmentParameters();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		return this.app.adapter.storeDataLifeCycleSteps?.({ loading: false, data: this, error: null });
	}

	set(obj: ILifeCycleStep[] | undefined): void {
		this.data = obj;
		this.change();
	}

	update(data: ILifeCycleStep[]): void {
		data.forEach((item: ILifeCycleStep) => {
			const i = this.data?.find((x: ILifeCycleStep) => x.id === item.id);
			if (i) return;
			this.data = [...this.data ?? [], item];
		});
		this.change();
	}

	addColors(data: {itemId: string, color:string}[]) {
		if (!this.data) return;
		data.forEach((item) => {
			const lcs = this.data?.find((i) => i.id === item.itemId);
			if (lcs) lcs.color = item.color;
		});
		this.change();
	}

	clearColors() {
		if (!this.data) return;
		this.data.forEach((item) => {
			item.color = undefined;
		});
		this.change();
	}
}
