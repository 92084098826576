import React, { useId } from 'react';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { translate } from '../../../../infrastructure/translations/translate';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { DashboardPinButton } from '../../DashboardPin';
import { useModal } from '../../../../viewComponents/modal/useModal';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../../Comparison/CreateDashboardComparisonBenchmarkModal';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { getColors } from '../../../../config';
import { LineChart, LineChartData } from '../../../../viewComponents/graphs/bars/LineChart';
import { ChartProps } from 'react-chartjs-2';
import {
	DashboardPluginGraphBarLegend,
	DashboardEvolutionPluginGraphBarRefDiff
} from '../../DashboardPluginGraphBarLegend';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import {
	GroupedBarChart,
	GroupedBarChartData
} from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { TooltipItem } from 'chart.js/auto';

import { DashboardSelectReference } from '../../DashboardSelectReference';
import { compareEvolutionValueToRefValue } from '../DashboardEvolution';

type DashboardPerimetersEvolutionGraphDataLine = {
	label: string;
	color?: string;
	dashed?: boolean;
	values: {
		scope: string;
		value: number;
	}[];
}

export interface DashboardPerimetersEvolutionGraphData {
	indicators: string[];
	lifeCycleSteps: string[];
	footprintType: number;
	indicator: string;
	domain: string;
	lifeCycleStep: string;
	graph: (DashboardPerimetersEvolutionGraphDataLine | null)[];
	reference: string | null;
}

const options: ChartProps<'line' | 'bar'>['options'] = {
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar' | 'line'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	}
};

export const DashboardPerimetersEvolutionGraph = ({ data, showTable, histogram, unit }: { data: DashboardPerimetersEvolutionGraphData, showTable: boolean, histogram?: boolean, unit: string }): JSX.Element => {
	const { graph: graphData, reference } = data;
	const id = useId();

	const years = graphData
		.filter(e => e)
		.map(e => e?.values.map(v => v.scope))
		.flat()
		.filter(unique)
		.sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	const graphLine: (LineChartData | null)[] = years.map((year) => ({
		label: year ?? '',
		values: graphData
			.map(d => {
				const find = d?.values.find(v => v.scope === year);
				return {
					scope: d?.label ?? '',
					value: find?.value ?? null,
					color: d?.color ?? 'black',
					dashed: d?.dashed ?? false
				};
			})
	}));

	graphLine.unshift(null);
	graphLine.push(null);
	if (graphLine.length < 4) {
		graphLine.unshift(null);
		graphLine.push(null);
	}

	const colors = [...getColors((graphData.find(d => d)?.values.length ?? 1), true)];

	const graphBar: (GroupedBarChartData | null)[] = graphData.map(d => d ? ({
		label: d.label,
		values: years
			.filter(y => d.values.find(v => v.scope === y))
			.map((y, i) => ({
				scope: y ?? '',
				value: d.values.find(v => v.scope === y)?.value ?? 0,
				color: d.color === 'black' ? i == d.values.length - 1 ? 'black' : 'gray' : undefined,
			}))
	}) : null);

	return <div className="dashboard_graph">
		{!showTable && <>
			<div className="dashboard_chart_container">
				{unit && <div className="chart_indicator_unit">
					{unit}
				</div>}
				{!histogram && <LineChart
					data={graphLine}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id)]}
				/>}
				{histogram && <GroupedBarChart
					data={graphBar}
					datasetRef={reference}
					options={options}
					plugins={[DashboardPluginGraphBarLegend(id), DashboardEvolutionPluginGraphBarRefDiff()]}
					thin={15}
					colors={colors}
					percentage={1}
					categoryPercentage={.5}
					borderRadius={3}
				/>}
			</div>
			<div id={id} className="dashboard_graph_legend"/>
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					{years.map((year, i) => (
						<th key={i}>{year}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{graphData
					.filter(d => d)
					.map((d, i) => (<tr key={i}>
						<td>{d?.label}</td>
						{years.map((year, j) => {
							const find = d?.values.find(d => d.scope === year);
							const refValue = d?.values.find(v => v.scope === reference)?.value;
							return (
								<td style={{ fontWeight: reference === year ? 'bold' : 'normal' }} key={j}>
									<span className={'number value'}>
										{resultValue(find?.value)}
										{reference && year !== reference &&
											<span className="number ref">{compareEvolutionValueToRefValue(refValue, find?.value)}</span>}
									</span>
								</td>
							);
						})}
					</tr>))
				}
			</tbody>
		</table>}
	</div>;
};

type ParsedDataset = {
	dataset: DashboardComparisonData['datasets'][number],
	value: number
}
type GroupedByDataset = {
	name: string,
	study: string,
	company: string,
	values: {
		year: string,
		value: number[]
	}[]
}

const DashboardPerimetersEvolution = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);

	const [selectFootprintType, setSelectFootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');

	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [histogram, setShowHistogram] = React.useState<boolean>(false);
	const [reference, setReference] = React.useState<string | null>(null);
	const isLineMode = !histogram && !showTable;

	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	const DifferentStudyNames = data.datasets.map(d => d.study).filter(unique).length > 1;
	const DifferentCompanyNames = data.datasets.map(d => d.company).filter(unique).length > 1;
	const getName = (d: {
		name?: string,
		study?: string,
		company?: string
	}): string => {
		let name = '';
		if (DifferentCompanyNames) name = ` - ${d.company}`;
		if (DifferentStudyNames) name = ` - ${d.study}` + name;
		return d.name + name;
	};
	const datasetNames = data.datasets.map(d => getName(d)).filter(unique);
	const colors = getColors(datasetNames.length, true);
	const datasetColors = datasetNames.map((name, i) => ({
		name,
		color: colors[i]
	}));

	const parseDataset = (dataset: DashboardComparisonData['datasets'][number]): ParsedDataset => {
		const value = dataset.equipments.reduce((accEquipment, equipment) => {
			if (selectDomain !== 'all' && equipment.domain !== selectDomain) return accEquipment;
			return accEquipment + equipment.impacts.reduce((accImpact, impact) => {
				return accImpact + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
			}, 0);
		}, 0);
		return {
			dataset,
			value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType, dataset, value))
		};
	};

	const reduceToGroupedDataset = (acc: GroupedByDataset[], dataset: ParsedDataset) => {
		const datasetName = dataset.dataset.name;
		const studyName = dataset.dataset.study;
		const studyYear = dataset.dataset.year;
		const companyName = dataset.dataset.company;

		const find = acc.find(d => d.name === datasetName && d.study === studyName && d.company === companyName);
		if (find) {
			const findDataset = find.values.find(a => a.year === studyYear);
			if (findDataset) {
				findDataset.value.push(dataset.value);
			} else {
				find.values.push({
					year: studyYear,
					value: [dataset.value]
				});
			}
		} else {
			acc.push({
				name: datasetName,
				study: studyName,
				company: companyName,
				values: [{
					year: studyYear,
					value: [dataset.value]
				}]
			});
		}
		return acc;
	};

	const valuesByDataset = data.datasets.map(parseDataset);

	const groupedDataset = valuesByDataset.reduce(reduceToGroupedDataset, []);

	const graphData: (DashboardPerimetersEvolutionGraphDataLine | null)[] = groupedDataset.map((e) => ({
		label: getName(e),
		color: datasetColors.find(d => d.name === getName(e))?.color,
		values: e.values.map((v) => ({
			scope: v.year,
			value: v.value.reduce((a, c) => a + c, 0)
		}))
	}));

	if (benchmark) {
		const benchmarkDatasets = benchmark.data.datasets
			.map(d => ({
				...d,
				referenceFlux: benchmark.data.datasets
					.filter(c => c.year === d.year)
					.reduce((acc, c) => acc + c.referenceFlux, 0)
			})).map(parseDataset);
		const benchmarkGroupedDatasets = benchmarkDatasets.reduce(reduceToGroupedDataset, []);
		graphData.push(...benchmarkGroupedDatasets.map(d => ({
			label: d.name,
			color: 'black',
			dashed: true,
			values: d.values.map(v => ({
				scope: v.year,
				value: v.value.reduce((a, c) => a + c, 0)
			}))
		})));
	}

	const saveData = {
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		indicator: selectIndicator,
		domain: selectDomain,
		lifeCycleStep: selectLifeCycleStep,
		graph: graphData,
		reference
	};

	return <div className="dashboard_card dashboard_card_color_yellow">
		<h3>{translate('dashboard.title.perimetersEvolution')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={e => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectIndicator}
				onChange={e => setSelectIndicator(e.target.value)}
			>
				<option
					value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName})
						- {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.name}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
			{!isLineMode && <DashboardSelectReference benchmark={benchmark} data={data} reference={reference} setReference={setReference} isEvolution={true}/>}
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced"/>
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowHistogram(!histogram)}
				>
					<div className="dashboard_icon">
						{histogram && <i className="fa-solid fa-chart-column"/>}
						{!histogram && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{histogram && translate('dashboard.action.histogram.hide')}
						{!histogram && translate('dashboard.action.histogram.show')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column"/>}
						{!showTable && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardPerimetersEvolution'}/>
			</div>
			<DashboardPerimetersEvolutionGraph
				data={saveData}
				showTable={showTable}
				histogram={histogram}
				unit={indicators.find(i => i.id === selectIndicator)?.unit ?? translate('results.valuePBCI') as string}
			/>
		</div>
	</div>;
};

export default DashboardPerimetersEvolution;