import { Entity } from '../core/Entity';
import {
	ApolloClientMutateEditLcaInventory,
	ApolloClientMutateEditLcaMaturity,
	ApolloClientMutateEditLcaTemplate,
	ApolloClientQueryLcaInventory,
	ApolloClientQueryLcaMaturity,
	ApolloClientQueryLcaTemplate,
	ApolloClientQueryLifeCycleAssessmentParameters
} from '../../infrastructure/ApolloClass/LifeCycleAssessmentClass';
import { MUTATE_EDIT_LCA_INVENTORY } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_LCA_INVENTORY';
import { MUTATE_EDIT_LCA_MATURITY } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_LCA_MATURITY';
import { MUTATE_EDIT_LCA_TEMPLATE } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_LCA_TEMPLATE';
import { QUERY_LCA_INVENTORY } from '../../infrastructure/ApolloClient/requests/QUERY_LCA_INVENTORY';
import { QUERY_LCA_MATURITY } from '../../infrastructure/ApolloClient/requests/QUERY_LCA_MATURITY';
import { QUERY_LCA_TEMPLATE } from '../../infrastructure/ApolloClient/requests/QUERY_LCA_TEMPLATE';
import { QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS } from '../../infrastructure/ApolloClient/requests/QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS';
import {
	ILifeCycleAssessmentParameters,
} from '../interfaces/IQueryInventoryExtraContent';
import { ILcaInventory } from '../interfaces/ILcaInventory';
import { IMutateEditLcaInventoryInput } from '../interfaces/IMutateEditLcaInventory';
import { ILcaMaturity } from '../interfaces/ILcaMaturity';
import { IMutateEditLcaMaturityInput } from '../interfaces/IMutateEditLcaMaturity';
import { ILcaTemplate } from '../interfaces/ILcaTemplate';
import { IMutateEditLcaTemplateInput } from '../interfaces/IMutateEditLcaTemplate';

export class LifeCycleAssessmentEntity extends Entity {
	public isQuerying = false;

	initialization() {
		this.app.adapter.mutateEditLcaInventory ??= this.app.installer(ApolloClientMutateEditLcaInventory, MUTATE_EDIT_LCA_INVENTORY);
		this.app.adapter.mutateEditLcaMaturity ??= this.app.installer(ApolloClientMutateEditLcaMaturity, MUTATE_EDIT_LCA_MATURITY);
		this.app.adapter.mutateEditLcaTemplate ??= this.app.installer(ApolloClientMutateEditLcaTemplate, MUTATE_EDIT_LCA_TEMPLATE);
		this.app.adapter.queryLcaInventory ??= this.app.installer(ApolloClientQueryLcaInventory, QUERY_LCA_INVENTORY);
		this.app.adapter.queryLcaMaturity ??= this.app.installer(ApolloClientQueryLcaMaturity, QUERY_LCA_MATURITY);
		this.app.adapter.queryLcaTemplate ??= this.app.installer(ApolloClientQueryLcaTemplate, QUERY_LCA_TEMPLATE);
		this.app.adapter.queryLifeCycleAssessmentParameters ??= this.app.installer(ApolloClientQueryLifeCycleAssessmentParameters, QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS);
		this.app.adapter.storeDataLca?.({ loading: false, data: this, error: null });
	}

	change(): Promise<void> | undefined {
		return undefined;
	}

	set(): void {
		this.change();
	}

	update(): void {
		this.change();
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryLifeCycleAssessmentParameters(): Promise<ILifeCycleAssessmentParameters | undefined> {
		this.isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryLifeCycleAssessmentParameters).catch(() => {
			this.isQuerying = false;
			return undefined;
		});
		this.isQuerying = false;
		if (!data) return undefined;
		this.app.entities.lifeCycleSteps.update(data.lifeCycleAssessmentParameters.lifeCycleSteps);
		this.app.entities.indicators.update(data.lifeCycleAssessmentParameters.indicators);
		return data.lifeCycleAssessmentParameters;
	}

	async queryLcaInventory(): Promise<ILcaInventory[]> {
		const data = await this.callApi(this.app.adapter.queryLcaInventory);
		return data.lcaModels.inventories;
	}

	async mutateEditLcaInventory(input: IMutateEditLcaInventoryInput): Promise<ILcaInventory> {
		const data = await this.callApi(this.app.adapter.mutateEditLcaInventory, input);
		return data.mutateEditLcaInventory.inventory;
	}

	async queryLcaMaturity(): Promise<ILcaMaturity[]> {
		const data = await this.callApi(this.app.adapter.queryLcaMaturity);
		return data.lcaModels.maturities;
	}

	async mutateEditLcaMaturity(input: IMutateEditLcaMaturityInput): Promise<ILcaMaturity> {
		const data = await this.callApi(this.app.adapter.mutateEditLcaMaturity, input);
		return data.mutateEditLcaMaturity.maturity;
	}

	async queryLcaTemplate(): Promise<ILcaTemplate[]> {
		const data = await this.callApi(this.app.adapter.queryLcaTemplate);
		return data.lcaModels.templates;
	}

	async mutateEditLcaTemplate(input: IMutateEditLcaTemplateInput): Promise<ILcaTemplate> {
		const data = await this.callApi(this.app.adapter.mutateEditLcaTemplate, input);
		return data.mutateEditLcaTemplate.template;
	}
}