
import { gql } from '@apollo/client';

export const QUERY_LAUNCHER_EQUIPMENTS_VERSIONS = gql`
    query QueryLauncherEquipmentsVersions
    (
        $resultLauncherId: String!
    )
    {
        launcherEquipmentsVersions(
            resultLauncherId: $resultLauncherId
        ) {
            equipmentTypeId,
            equipmentTypeName,
            equipmentTypeSource,
            equipmentTypeShortNameAndSpecification
            dataReference {
            	reference,
            	isHash
            }
            version,
            updatedAt
        }
    }
`;
