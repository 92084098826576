import { gql } from '@apollo/client';

export const MUTATE_USER_ASSIGNMENTS = gql`
	mutation UserAssignments(
		$userAssignments: [UserAssignmentInputType!]!
	) {
		mutateUserAssignments(
			userAssignments: $userAssignments
		) {
			status
			equipments {
                id,
                name,
                help,
                unit,
                priority,
                quantity,
                quality,
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
				customElectricityConsumption,
                comment,
                assigned,
                assignedCount,
                forbidden,
                equipmentTypeId,
                category,
                cmdb
			},
			tempsMailList
		}
	}
`;
