import { ISampleListItem } from '../../domain/interfaces/ISample';
import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { SampleEntity } from '../../domain/entities/SampleEntity';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type SamplesHookType = IDataDTO & { entity: SampleEntity | undefined, samples: ISampleListItem[] };

export const useSamples = (functionalUnitNames?: string[]): SamplesHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataSampleListStatus);
	const samples = entity?.get() ?? [];
	if (functionalUnitNames) return { loading, error, entity, samples: samples.filter(s => functionalUnitNames.includes(s.functionalUnit)) };
	return { loading, error, entity, samples };
};