import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQueryComputingHistoryInput, IQueryComputingHistoryOutput } from '../../domain/interfaces/IQueryComputingHistory';
import { IQueryLauncherEquipmentsVersionsInput, IQueryLauncherEquipmentsVersionsOutput } from '../../domain/interfaces/IQueryLauncherEquipmentsVersions';
import { IMutateManageResultLauncherInput, IMutateManageResultLauncherOutput } from '../../domain/interfaces/IMutateManageResultLauncher';

export class ApolloClientMutateManageResultLauncher extends ApolloClientProvider<IMutateManageResultLauncherOutput, IMutateManageResultLauncherInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateManageResultLauncherInput): Promise<IMutateManageResultLauncherOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryComputingHistory extends ApolloClientProvider<IQueryComputingHistoryOutput, IQueryComputingHistoryInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryComputingHistoryInput): Promise<IQueryComputingHistoryOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryLauncherEquipmentsVersions extends ApolloClientProvider<IQueryLauncherEquipmentsVersionsOutput, IQueryLauncherEquipmentsVersionsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryLauncherEquipmentsVersionsInput): Promise<IQueryLauncherEquipmentsVersionsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}