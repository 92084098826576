import { useSelector } from 'react-redux';
import { State } from '../../../store';
import React from 'react';
import { IUserNotification } from '../../../domain/interfaces/IUserNotification';
import { removeUserNotification } from '../../../store/dispatchers';
import { translate } from '../../../infrastructure/translations/translate';
import { useNavigate } from 'react-router-dom';
import './userNotification.scss';

const UserNotificationElement = ({ notif }: { notif: IUserNotification }): JSX.Element => {
	const [visible, setVisible] = React.useState(true);
	const navigate = useNavigate();

	const onClose = () => {
		if (notif.additionalCloseAction) notif.additionalCloseAction();
		setVisible(false);
		removeUserNotification(notif);
	};

	const redirectUser = () => {
		if (notif.redirectPath && typeof notif.redirectPath === 'string')
			navigate(notif.redirectPath);
		else if (notif.redirectPath && typeof notif.redirectPath === 'function')
			notif.redirectPath();
		onClose();
	};

	const isFooterAvailable = notif.footer && !!notif.redirectPath;

	if (!visible) return (<></>);

	return (
		<div className={'user_notification_element'}>
			<button className={'button_blank close'} aria-label={translate('close') as string} onClick={onClose}>X</button>
			<div className={'user_notification_element_title'}>{translate(notif.title, { return: () => <br/> })}</div>
			{notif.message && <div className={'user_notification_element_message'}>{translate(notif.message)}</div>}
			{isFooterAvailable && <div className={'user_notification_element_footer'}>
				<button className={'user_notification_element_close'} aria-label={translate('close') as string}
					onClick={onClose}>{translate('maybeLater') as string}</button>
				<button className={'user_notification_element_redirection'} aria-label={'Redirection'}
					onClick={redirectUser}>{translate('yes') as string} !</button>
			</div>}
		</div>
	);
};

const UserNotification = (): JSX.Element => {
	const notifications = useSelector((state: State) => state.userNotifications);
	if (!notifications?.length) return (<></>);

	// This can change if we want to implement a dynamic notification system (like a queue)
	const notification = notifications?.[0];

	return (
		<div className={`user_notification ${notification ? 'active' : ''}`}>
			{notification && <UserNotificationElement notif={notification}/>}
		</div>
	);
};

export default UserNotification;