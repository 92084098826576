import { gql } from '@apollo/client';

export const QUERY_DATA_VALIDATION = gql`
    query requestValidation (
        $datasetId: String!
    ) {
        dataset (id: $datasetId) {
            id,
            name,
            equipments {
                id,
                equipmentTypeName,
                equipmentTypeSpecification,
                equipmentTypeMetadata,
                name,
                unit,
                unitShortName,
                validated,
                specification,
                priority,
                quantity,
                unknown,
                validationQuantity,
                quantityCheck,
                lifetimeCheck,
                validationSampleQuantityMean,
                lifetime,
                validationLifetime,
                validationSampleLifetimeMean,
                quality,
                internalLifetime,
                reusePart,
                reuseLifetime,
				customElectricityConsumption, 
                comment,
                consultComment,
                forbidden,
                datasetsCount,
                equipmentQualityScore,
                category,
                categoryUnit,
                defaultLifetime,
                database
            },
            cmdb {
            	id,
            	name,
            	category,
            	block,
            	comment,
            	equipmentsInfo {
            		category,
            		quantity,
            		totalLifetime,
            		numberOfType,
            		validatedQuantity,
            		validatedLifetime,
            		validated
				}
			},
        }
    }
`;
