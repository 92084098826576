import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseDataResults, ResponseDataResultsInput } from '../../domain/data/ResponseDataResults';
import { IResultListManagementInput, IResultListManagementOutput, IResultListOutput } from '../../domain/interfaces/IResultList';
import { IMutateResultsComparisonInput, IMutateResultsComparisonOutput } from '../../domain/interfaces/IMutateResultsComparison';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQueryResultTypesInput, IQueryResultTypesOutput } from '../../domain/interfaces/IResultType';
import { IMutateArchiveResultsInput, IMutateArchiveResultsOutput } from '../../domain/interfaces/IMutateArchiveResults';
import { IMutateExportInput, IMutateExportOutput } from '../../domain/interfaces/IMutateExport';
import { IQueryEquipmentGroupByInput, IQueryEquipmentGroupByOutput } from '../../domain/interfaces/IQueryEquipmentGroupBy';

export class ApolloClientQueryDataResults extends ApolloClientProvider<ResponseDataResults, ResponseDataResultsInput> {}
export class ApolloClientQueryResultTypes extends ApolloClientProvider<IQueryResultTypesOutput, IQueryResultTypesInput> {}
export class ApolloClientQueryResultLists extends ApolloClientProvider<IResultListOutput> {}

export class ApolloClientMutateResultList extends ApolloClientProvider<IResultListManagementOutput, IResultListManagementInput> {}
export class ApolloClientMutateResultsComparison extends ApolloClientProvider<IMutateResultsComparisonOutput, IMutateResultsComparisonInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateResultsComparisonInput): Promise<IMutateResultsComparisonOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateArchiveResults extends ApolloClientProvider<IMutateArchiveResultsOutput, IMutateArchiveResultsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateArchiveResultsInput): Promise<IMutateArchiveResultsOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateExport extends ApolloClientProvider<IMutateExportOutput, IMutateExportInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateExportInput): Promise<IMutateExportOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryEquipmentGroupBy extends ApolloClientProvider<IQueryEquipmentGroupByOutput, IQueryEquipmentGroupByInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryEquipmentGroupByInput): Promise<IQueryEquipmentGroupByOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}